import { Paper, Select, Switch, Text, Textarea, TextInput } from "@mantine/core";
import { Create, useForm, useSelect } from "@refinedev/mantine";
import { EditorCore, EDITOR_JS_TOOLS } from 'editorjsTools';
import { createReactEditorJS } from 'react-editor-js';

import { IService } from "pages/interfaces";
import React from "react";

const ReactEditorJS = createReactEditorJS()

let editorValue = {}

export const PageCreate: React.FC = () => {
    const [getEditorOutput, setEditorOutput] = React.useState(editorValue)

    const editorInstance = React.useRef<null | EditorCore>(null);

    const handleInitialize = React.useCallback((instance: EditorCore) => {
        editorInstance.current = instance;
    }, [])

    const onEditorChange = React.useCallback((api: any) => {
        api.saver.save().then((outputData: any) => {
            setEditorOutput(outputData)
        });
    }, [])

    const { saveButtonProps, getInputProps, errors } = useForm({
        initialValues: {
            title: "",
            description: "",
            isVisible: true,
            parentID: null,
            content: "",
        },
        transformValues: (values) => ({
            title: { en: values.title },
            description: values.description,
            isVisible: values.isVisible,
            parentID: values.parentID,
            content: getEditorOutput,
        }),
        validate: {
            title: (value) => (value.length < 2 ? "Title too short" : null),
            description: (value) => (value.length > 250 ? "Description too long" : null),
            // isVisible: (value) =>
            //     value.length <= 0 ? "Status is required" : null,
            // parent: {
            //     id: (value) =>
            //         value.length <= 0 ? "Category is required" : null,
            // },
            // content: (value) =>
            //     value.length < 10 ? "Too short content" : null,
        },
    });

    const { queryResult } = useSelect<IService>({
        resource: "service",
        // onSearch: (value) => [
        //     {
        //         field: "title.en",
        //         operator: "contains",
        //         value,
        //     },
        // ],
        meta: {
            fields: [
                "id",
                "title",
            ],
        },
    });

    const parentOptions = queryResult.data?.data.map((item) => ({
        label: item.title.en ?? item.id,
        value: item.id,
    }));

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                    label="Title"
                    placeholder="Title"
                    withAsterisk
                    {...getInputProps("title")}
                />
                <Textarea
                    mt={8}
                    label="Description"
                    placeholder="Description"
                    {...getInputProps("description")}
                />
                <Text mt={8} weight={500} size="sm">
                    Visible
                </Text>
                <Switch
                    size="md"
                    labelPosition="left"
                    {...getInputProps('isVisible', { type: 'checkbox' })}
                />
                <Select
                    mt={8}
                    label="Parent"
                    placeholder="Pick one"
                    withinPortal
                    searchable
                    clearable
                    {...getInputProps("parentID")}
                    data={parentOptions ?? []}
                />
                <Text mt={8} weight={500} size="sm">
                    Content
                </Text>
                <Paper shadow="xs" p="md" withBorder>
                    <ReactEditorJS
                        onChange={onEditorChange}
                        onInitialize={handleInitialize}
                        tools={EDITOR_JS_TOOLS}
                        placeholder="Type something..."
                    />
                    {/* <ReactEditorJS tools={EDITOR_JS_TOOLS} placeholder="Bla bla..." /> */}
                    {/* <MDEditor
                    data-color-mode="light"
                    {...getInputProps("content")}
                /> */}
                </Paper>
                {errors.content && (
                    <Text mt={2} weight={500} size="xs" color="red">
                        {errors.content}
                    </Text>
                )}
            </form>
        </Create>
    );
};
