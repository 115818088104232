export const UserSorterEnums = {
    "id": "ID",
    "firstName": "FIRST_NAME",
    "lastName": "LAST_NAME",
    "email": "EMAIL",
}
export const RequestSorterEnums = {
    "id": "ID",
    "plannedFor": "PLANNED_FOR",
    "referralCode": "REFERRAL_CODE",
}
export const ClinicSorterEnums = {
    "id": "ID",
}
export const ImageSorterEnums = {
    "id": "ID",
}
export const ServiceSorterEnums = {
    "id": "ID",
}
export const LocationSorterEnums = {
    "id": "ID",
}
export const OrganizationSorterEnums = {
    "id": "ID",
}
export const FeatureSorterEnums = {
    "id": "ID",
}
export const FeatureGroupSorterEnums = {
    "id": "ID",
}
