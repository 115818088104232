import { Badge, Box, Button, Collapse, Flex, Group, Modal, Paper, Select, SimpleGrid, Switch, Table, Text, Textarea, TextInput } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { useInvalidate, useParsed } from "@refinedev/core";
import { Edit, EditButton, useForm, useSelect } from "@refinedev/mantine";
import { IconBandage, IconEye, IconEyeOff, IconPhoto, IconTrash, IconUsers } from '@tabler/icons';
import { AddButton } from "components/buttons/add";
import { RemoveButton } from "components/buttons/remove";
import { CardImageGrid } from "components/micro";
import { EditorCore, EDITOR_JS_TOOLS } from 'editorjsTools';
import { createReactEditorJS } from 'react-editor-js';
import * as ClinicServicesPages from "../clinic_services";
import * as ImagesPages from "../images";

import { IClinic, IPerson } from "pages/interfaces";
import React, { useState } from "react";

const ReactEditorJS = createReactEditorJS()

let editorValue = {}

export const PageEdit = () => {
    const { id } = useParsed();
    const [opened, { toggle }] = useDisclosure(false);
    const [drawerOpenedStaff, setDrawerOpenedStaff] = useState(false);
    const [drawerOpenedServices, setDrawerOpenedServices] = useState(false);
    const [drawerOpenedImages, setDrawerOpenedImages] = useState(false);
    const [staffSelectValue, setStaffSelectValue] = useState<string | null>(null);

    const [getEditorOutput, setEditorOutput] = React.useState(editorValue)

    const editorInstance = React.useRef<null | EditorCore>(null);

    const handleInitialize = React.useCallback((instance: EditorCore) => {
        editorInstance.current = instance;
    }, [])

    const onEditorChange = React.useCallback((api: any) => {
        api.saver.save().then((outputData: any) => {
            setEditorOutput(outputData)
        });
    }, [])

    const invalidate = useInvalidate();


    const {
        getInputProps,
        saveButtonProps,
        setFieldValue,
        errors,
        refineCore: { queryResult, formLoading },
    } = useForm({
        refineCoreProps: {
            id,
            resource: "clinic",
            invalidates: ["all"],
            meta: {
                fields: [
                    "id",
                    "title",
                    "description",
                    "content",
                    "isVisible",
                    {
                        "images": [
                            "id",
                            "sized(width: 200 height: 200)",
                        ]
                    },
                    {
                        "staff": [
                            "id",
                            "title",
                            "name",
                            {
                                "images": [
                                    "sized(width: 64 height: 64)",
                                ]
                            }
                        ]
                    },
                    {
                        "services": [
                            "id",
                            {
                                "service": [
                                    "id",
                                    "title",
                                ]
                            },
                            "showPricing",
                        ]
                    },
                ],
            },
            queryOptions: {
                onSuccess(data) {
                    // const { data: record } = data;
                    setEditorOutput(record?.content);
                },
            }
        },
        initialValues: {
            title: { en: "" },
            description: "",
            isVisible: false,
            content: "",
        },
        transformValues: (values) => ({
            title: values.title,
            description: values.description,
            isVisible: values.isVisible,
            content: getEditorOutput,
        }),
        validate: {
            title: (value) => (value.en.length < 2 ? "Title too short" : null),
            description: (value) => (value.length > 250 ? "Description too long" : null),
            // parent: {
            //     id: (value) =>
            //         value.length <= 0 ? "Category is required" : null,
            // },
            // content: (value) =>
            //     value.length < 10 ? "Too short content" : null,
        },
    });

    const record = queryResult?.data?.data as IClinic;

    const { queryResult: queryResultStaff } = useSelect<IPerson>({
        resource: "person",
        filters: [
            {
                field: "isVisible",
                value: true,
                operator: "eq",
            }
        ],
        meta: {
            fields: [
                "id",
                "title",
                "name",
                "isVisible",
                "createTime",
            ],
        },
    });

    const staffOptions = queryResultStaff.data?.data.map((item) => (
        {
            label: `${item.title} ${item.name}` ?? item.id,
            value: item.id,
        }
    ));

    // staffOptions?.filter(function (el) {
    //     return !record?.staff?.find((item) => (item.id === el.value));
    // });

    const RemoveButtonStaff: React.FC<{ id: string }> = ({
        id
    }) => {
        return <RemoveButton
            hideText
            radius={100}
            svgIconProps={{ size: 12 }}
            meta={
                {
                    operation: "updateClinic",
                    variables: {
                        input: {
                            value: {
                                "removeStaffIDs": [
                                    id
                                ],
                            },
                            type: "UpdateClinicInput",
                            required: true
                        },
                        id: {
                            type: "ID",
                            value: record?.id,
                            required: true,
                        },
                    },
                    fields: ["id"],
                }
            }
            onSuccess={(data) => {
                invalidate({
                    resource: "clinic",
                    invalidates: ["detail"],
                    id: data.data?.id,
                });

                // setDrawerOpened(false);
            }
            }
        />
    }


    const DeleteImage: React.FC<{ id: string }> = ({
        id
    }) => {
        return <RemoveButton
            hideText
            size="xs"
            // variant="filled"
            // color="red"
            radius="sm"
            compact
            // uppercase
            svgIconProps={{ size: 12 }}
            meta={
                {
                    operation: "updateClinic",
                    variables: {
                        input: {
                            value: {
                                "removeImageIDs": [
                                    id
                                ],
                            },
                            type: "UpdateClinicInput",
                            required: true
                        },
                        id: {
                            type: "ID",
                            value: record?.id,
                            required: true,
                        },
                    },
                    fields: ["id"],
                }
            }
            onSuccess={(data) => {
                invalidate({
                    resource: "clinic",
                    invalidates: ["detail"],
                    id: data.data?.id,
                });

                // setDrawerOpened(false);
            }
            }
        ><IconTrash /></RemoveButton>
    }

    return (
        <>

            <Modal
                opened={drawerOpenedImages}
                onClose={() => setDrawerOpenedImages(false)}
                title="Edit Gallery"
                padding="xl"
                size="xl"
            >

                <SimpleGrid
                    cols={2}
                    breakpoints={[
                        { maxWidth: 'sm', cols: 2, spacing: 'sm' },
                        { maxWidth: 'xs', cols: 1, spacing: 'sm' },
                    ]}
                >
                    <ImagesPages.PageCreate entityID={id} entity="clinic" />


                    {record?.images?.map((item: any) => (

                        <CardImageGrid
                            key={item.id}
                            image={item.sized}
                            bottomBar={
                                <Group position="apart" spacing="xs">
                                    <DeleteImage id={item.id} />
                                </Group>
                            }
                        />

                    ))}

                </SimpleGrid>

            </Modal>

            {/* Staff Drawer */}
            <Modal
                opened={drawerOpenedStaff}
                onClose={() => setDrawerOpenedStaff(false)}
                title="Add Staff"
                padding="xl"
                size="xl"
            // position="right"
            >

                <Flex
                    mt="lg"
                    gap="sm"
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                >
                    {
                        record?.staff?.map((item) => (
                            <Badge key={item.id} variant="outline" sx={{ paddingRight: 3 }} rightSection={<RemoveButtonStaff id={item.id} />}>
                                {item.name}
                            </Badge>
                        ))
                    }
                </Flex>

                <Select
                    mt={8}
                    label="Staff"
                    placeholder="Pick one"
                    data={staffOptions ?? []}
                    value={staffSelectValue}
                    onChange={setStaffSelectValue}
                />
                <Box
                    mt={8}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <AddButton
                        meta={
                            {
                                operation: "updateClinic",
                                variables: {
                                    input: {
                                        value: {
                                            "addStaffIDs": [
                                                staffSelectValue
                                            ],
                                        },
                                        type: "UpdateClinicInput",
                                        required: true
                                    },
                                    id: {
                                        type: "ID",
                                        value: id,
                                        required: true,
                                    },
                                },
                                fields: ["id"],
                            }
                        }
                        onSuccess={(data) => {
                            invalidate({
                                resource: "clinic",
                                invalidates: ["detail"],
                                id: data.data?.id,
                            });

                            // setDrawerOpened(false);
                        }
                        }
                    // svgIconProps={{ size: 16 }}
                    />
                </Box>
            </Modal>

            {/* Staff Drawer */}
            <Modal
                opened={drawerOpenedServices}
                onClose={() => setDrawerOpenedServices(false)}
                title="Add Service"
                padding="xl"
                size="xl"
            // position="right"
            >

                <Table captionSide="bottom" verticalSpacing="xs" fontSize="xs" striped highlightOnHover>
                    <tbody>
                        {record?.services?.map((element) => (
                            <tr key={element.id}>
                                <td>{element.service.title.en}</td>
                                <td align="right">
                                    <Group position="right">
                                        {element.showPricing ? <Badge size="xs" color="cyan">Price Visible</Badge> : <Badge size="xs" color="pink">Price Hidden</Badge>}
                                        <EditButton
                                            resource="clinicService"
                                            compact
                                            size="xs"
                                            recordItemId={element.id}
                                            hideText
                                        />
                                    </Group>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>


                <ClinicServicesPages.PageCreate clinicID={id} />
            </Modal>



            <Edit
                saveButtonProps={saveButtonProps}
            >
                <form>
                    <TextInput
                        mt={8}
                        label="Title"
                        placeholder="Title"
                        withAsterisk
                        {...getInputProps("title.en")}
                    />
                    <Textarea
                        mt={8}
                        label="Description"
                        placeholder="Description"
                        {...getInputProps("description")}
                    />

                    <Text mt={8} weight={500} size="sm">
                        Visible
                    </Text>
                    <Switch
                        size="md"
                        labelPosition="left"
                        {...getInputProps('isVisible', { type: 'checkbox' })}
                    />

                    <Flex
                        mt="lg"
                        gap="sm"
                        justify="flex-start"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                    >
                        <Button
                            size="sm"
                            variant="light"
                            compact
                            rightIcon={<IconBandage size={14} />}
                            onClick={() => setDrawerOpenedServices(true)}
                        >
                            Services
                        </Button>

                        <Button
                            size="sm"
                            variant="light"
                            compact
                            rightIcon={<IconUsers size={14} />}
                            onClick={() => setDrawerOpenedStaff(true)}
                        >
                            Staff
                        </Button>

                        <Button
                            size="sm"
                            variant="light"
                            compact
                            rightIcon={opened ? <IconEyeOff size={14} /> : <IconEye size={14} />}
                            onClick={toggle}
                        >
                            About
                        </Button>

                        <Button
                            size="sm"
                            variant="light"
                            compact
                            rightIcon={<IconPhoto size={14} />}
                            onClick={() => setDrawerOpenedImages(true)}
                        >
                            Gallery
                        </Button>
                    </Flex>
                    <Collapse in={opened} mt="md">
                        <Paper shadow="xs" p="md" withBorder>
                            {record?.content && !formLoading && <ReactEditorJS
                                onChange={onEditorChange}
                                onReady={() => console.log('Start!')}
                                onInitialize={handleInitialize}
                                tools={EDITOR_JS_TOOLS}
                                placeholder="Type something..."
                                defaultValue={record?.content}
                            />}
                        </Paper>
                    </Collapse>
                    {errors.content && (
                        <Text mt={2} weight={500} size="xs" color="red">
                            {errors.content}
                        </Text>
                    )}
                </form>
            </Edit>
        </>
    );
};
