import { useShow, useParsed } from "@refinedev/core";
import {
    Show,
} from "@refinedev/mantine";
import { Title, Text, Paper, Group, CopyButton, Button } from "@mantine/core";

import { IOrganization } from "pages/interfaces";
import { IconCopy, IconCheck } from '@tabler/icons';

export const PageShow = () => {
    const { id } = useParsed();

    const { queryResult } = useShow<IOrganization>({
        id,
        resource: "organization",
        meta: {
            fields: [
                "id",
                "name",
                "legalName",
                "createTime",
            ],
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return <Show isLoading={isLoading}>
        <Title order={5}>Id</Title>

        <CopyButton value={record?.id as string}>
            {({ copied, copy }) => (
                <Button size="xs" compact radius="xl" color={copied ? 'lime' : ''} onClick={copy}>
                    {record?.id}
                </Button>
            )}
        </CopyButton>

        <Title mt="md" order={5}>Name</Title>
        <Text>{record?.name}</Text>

        <Title mt="md" order={5}>Legal Name</Title>
        <Text>{record?.legalName}</Text>
    </Show>;

};
