import { Paper, Switch, Text, Textarea, TextInput } from "@mantine/core";
import { Create, useForm } from "@refinedev/mantine";
import { EditorCore, EDITOR_JS_TOOLS } from 'editorjsTools';
import { createReactEditorJS } from 'react-editor-js';

import { CheckerState, SlugInput } from 'components/slug';
import slug from 'slug';

import React from "react";

const ReactEditorJS = createReactEditorJS()

let editorValue = {}

export const PageCreate: React.FC = () => {
    const [isSlugManuallyChanged, setIsSlugManuallyChanged] = React.useState(false);
    const flagForced = (value: boolean) => {
        setIsSlugManuallyChanged(value);
    }

    const [slugStatus, setSlugStatus] = React.useState<CheckerState>('invalid');
    const checkSlugStatus = (value: CheckerState) => {
        setSlugStatus(value);
    }

    const [slugValue, setSlugValue] = React.useState('');

    const [getEditorOutput, setEditorOutput] = React.useState(editorValue)

    const editorInstance = React.useRef<null | EditorCore>(null);

    const handleInitialize = React.useCallback((instance: EditorCore) => {
        editorInstance.current = instance;
    }, [])

    const onEditorChange = React.useCallback((api: any) => {
        api.saver.save().then((outputData: any) => {
            setEditorOutput(outputData)
        });
    }, [])

    const { saveButtonProps, getInputProps, errors, values } = useForm({
        refineCoreProps: {
            resource: "person",
        },
        initialValues: {
            title: "",
            slug: "",
            description: "",
            name: "",
            isVisible: true,
            content: "",
        },
        transformValues: (values) => ({
            title: values.title,
            name: values.name,
            slug: slugValue,
            description: values.description,
            isVisible: values.isVisible,
            content: getEditorOutput,
        }),
        validate: {
            name: (value) => (value.length < 5 ? "Name too short" : null),
            description: (value) => (value.length > 250 ? "Description too long" : null),
            slug: () => {
                // return 'Invalid!'
                switch (slugStatus) {
                    case 'duplicate':
                        return 'Slug is already in use'
                    case 'invalid':
                        return 'Slug is too short or invalid'
                    case 'checking':
                        return 'Please wait...'
                    default:
                        return null;
                }
            },
        },
    });


    // const checkSlugUniqueness = async (slug: string) => {
    //     await new Promise((resolve) => setTimeout(resolve, 1000));

    //     const isUnique = slug !== 'existing-slug';

    //     if (!isUnique) {
    //         setFieldError('slug', 'This slug is in use');
    //     } else {
    //         setFieldError('slug', null);
    //     }
    // };

    const handleSlugChange = (value: string) => {
        const slugified = slug(value, { trim: false });
        setSlugValue(slugified)
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const slugified = slug(value);
        if (!isSlugManuallyChanged) {
            setSlugValue(slugified);
        }
    };

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                    label="Title"
                    placeholder="Title"
                    {...getInputProps("title")}
                />

                <TextInput
                    mt={8}
                    label="Name"
                    placeholder="Name"
                    withAsterisk
                    {...getInputProps("name")}
                    onChange={(e) => {
                        handleTitleChange(e);
                        getInputProps("name").onChange(e)
                    }}
                />

                <SlugInput
                    status={checkSlugStatus}
                    entity="person"
                    manuallyChanged={flagForced}
                    sourceValue={values.title + ' ' + values.name}
                    // onSourceValueChange={(value) => setFieldValue('title', value)}
                    // checkSlugUniqueness={checkSlugUniqueness}
                    slugProps={{
                        required: true,
                        withAsterisk: true,
                        label: "Slug",
                        placeholder: "Slug",
                        mt: 8,
                        ...getInputProps('slug'),
                        value: slugValue,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                            handleSlugChange(event.target.value),
                    }}
                />

                <Textarea
                    mt={8}
                    label="Description"
                    placeholder="Description"
                    {...getInputProps("description")}
                />

                <Text mt={8} weight={500} size="sm">
                    Visible
                </Text>
                <Switch
                    size="md"
                    labelPosition="left"
                    {...getInputProps('isVisible', { type: 'checkbox' })}
                />
                <Text mt={8} weight={500} size="sm">
                    About / Bio
                </Text>
                <Paper shadow="xs" p="md" withBorder>
                    <ReactEditorJS
                        onChange={onEditorChange}
                        onInitialize={handleInitialize}
                        tools={EDITOR_JS_TOOLS}
                        placeholder="Type something..."
                    />
                    {/* <ReactEditorJS tools={EDITOR_JS_TOOLS} placeholder="Bla bla..." /> */}
                    {/* <MDEditor
                    data-color-mode="light"
                    {...getInputProps("content")}
                /> */}
                </Paper>
                {errors.content && (
                    <Text mt={2} weight={500} size="xs" color="red">
                        {errors.content}
                    </Text>
                )}
            </form>
        </Create>
    );
};
