export const ViturasMark = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 256 256"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
    >
        <g transform="matrix(1,0,0,1,-106.787,-499.787)">
            <g transform="matrix(1.00957,0,0,1.00957,-2.8471,-6.9138)">
                <rect id="mark" x="108.596" y="501.9" width="253.574" height="253.574" fill="none" />
                <g id="mark1">
                    <g transform="matrix(0.990525,0,0,0.990525,-26123.3,37.5961)">
                        <g transform="matrix(1,0,0,1,0.207349,0)">
                            <path d="M26483.8,596.745C26483.8,581.17 26493.1,567.101 26507.5,561.006L26606.9,518.718C26611.7,516.672 26617.3,518.917 26619.3,523.726C26621.4,528.535 26619.1,534.1 26614.3,536.145L26514.9,578.433C26507.5,581.556 26502.8,588.765 26502.8,596.745C26502.8,604.726 26507.5,611.934 26514.9,615.057L26537.2,624.561L26547.9,649.684L26507.5,632.485C26493.1,626.39 26483.8,612.321 26483.8,596.745ZM26576.1,641.072L26604.1,652.979C26597.7,656.191 26594.5,663.607 26596.7,670.406L26586.8,666.195L26576.1,641.072Z" fill="rgb(214,164,248)" />
                        </g>
                        <g transform="matrix(1,0,0,1,0.207349,0)">
                            <path d="M26610.6,723.532C26595.1,723.532 26581,714.227 26574.9,699.894L26532.6,600.451C26530.6,595.642 26532.8,590.077 26537.6,588.031C26542.4,585.986 26548,588.23 26550,593.04L26592.3,692.483C26595.4,699.827 26602.7,704.594 26610.6,704.594C26618.6,704.594 26625.8,699.827 26628.9,692.483L26638.4,670.133L26663.6,659.449L26646.4,699.894C26640.3,714.227 26626.2,723.532 26610.6,723.532ZM26684.3,610.72L26680.1,620.624L26655,631.307L26666.9,603.308C26670.1,609.723 26677.5,612.842 26684.3,610.72Z" fill="rgb(201,134,246)" />
                        </g>
                        <g transform="matrix(1,0,0,1,0.207349,0)">
                            <path d="M26610.6,488.896C26602.7,488.896 26595.4,493.664 26592.3,501.008L26582.8,523.358L26557.7,534.041L26574.9,493.597C26581,479.263 26595.1,469.958 26610.6,469.958C26626.2,469.958 26640.3,479.263 26646.4,493.597L26688.7,593.04C26690.7,597.849 26688.5,603.414 26683.7,605.459C26678.8,607.504 26673.3,605.26 26671.2,600.451L26628.9,501.008C26625.8,493.664 26618.6,488.896 26610.6,488.896ZM26537,582.771L26541.2,572.867L26566.3,562.184L26554.4,590.183C26551.2,583.767 26543.8,580.648 26537,582.771Z" fill="rgb(228,195,251)" />
                        </g>
                        <g transform="matrix(1,0,0,1,0.207349,0)">
                            <path d="M26737.4,596.745C26737.4,612.321 26728.1,626.39 26713.8,632.485L26614.3,674.773C26609.5,676.818 26604,674.574 26601.9,669.765C26599.9,664.955 26602.1,659.39 26606.9,657.345L26706.4,615.057C26713.7,611.934 26718.5,604.726 26718.5,596.745C26718.5,588.765 26713.7,581.556 26706.4,578.433L26684,568.929L26673.3,543.807L26713.8,561.006C26728.1,567.101 26737.4,581.17 26737.4,596.745ZM26624.6,523.084L26634.5,527.296L26645.2,552.418L26617.2,540.512C26623.6,537.3 26626.7,529.883 26624.6,523.084Z" fill="rgb(187,104,244)" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

)
