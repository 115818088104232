import { useParsed } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/mantine";
import { Select, TextInput, Text, Paper, Switch } from "@mantine/core";

import { IOrganization } from "pages/interfaces";
import React from "react";


export const PageEdit = () => {
    const { id } = useParsed();

    const {
        getInputProps,
        saveButtonProps,
        setFieldValue,
        errors,
        refineCore: { queryResult },
    } = useForm({
        refineCoreProps: {
            id,
            resource: "organization",
            invalidates: ["all"],
            meta: {
                fields: [
                    "id",
                    "name",
                    "legalName",
                    "isActive",
                ],
            },
        },
        initialValues: {
            name: "",
            legalName: "",
            isActive: false,
        },
        transformValues: (values) => ({
            name: values.name,
            legalName: values.legalName,
            isActive: values.isActive,
        }),
        validate: {
            name: (value) => (value.length < 2 ? "Name too short" : null),
            legalName: (value) => (value.length < 2 ? "Legal Name too short" : null),
            // parent: {
            //     id: (value) =>
            //         value.length <= 0 ? "Category is required" : null,
            // },
            // content: (value) =>
            //     value.length < 10 ? "Too short content" : null,
        },
    });

    // queryResult?.isFetched
    // const { isLoading } = queryResult;
    const serviceData = queryResult?.data?.data as IOrganization;


    return <Edit
        saveButtonProps={saveButtonProps}
    >
        <form>
            <TextInput
                mt={8}
                label="Name"
                placeholder="Name"
                withAsterisk
                {...getInputProps("name")}
            />
            <TextInput
                mt={8}
                label="Legal Name"
                placeholder="Legal Name"
                withAsterisk
                {...getInputProps("legalName")}
            />
            <Text mt={8} weight={500} size="sm">
                Active
            </Text>
            <Switch
                size="md"
                labelPosition="left"
                {...getInputProps('isActive', { type: 'checkbox' })}
            />
            {errors.content && (
                <Text mt={2} weight={500} size="xs" color="red">
                    {errors.content}
                </Text>
            )}
        </form>
    </Edit>;
};
