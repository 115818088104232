import { Badge } from "@mantine/core";
import { EntEnums_RequestType } from "pages/interfaces";
import React from "react";

export const BadgeService: React.FC<{ serviceType: string }> = ({
    serviceType,
}) => {

    let badge;
    switch (serviceType) {
        case EntEnums_RequestType.consultation:
            badge = <Badge>Consultation</Badge>;
            break;
        case EntEnums_RequestType.serviceInfo:
            badge = <Badge color="lime">Info</Badge>;
            break;
        case EntEnums_RequestType.serviceBooking:
            badge = <Badge color="orange">Booking</Badge>;
            break;
        default:
            badge = <Badge color="gray">Help</Badge>;
            break;
    }

    return (
        badge
    );

};
