import { BaseKey, useInvalidate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/mantine";
import { Select, TextInput, Text, Paper, Switch, NumberInput, Textarea } from "@mantine/core";

import { IClinicService, IService } from "pages/interfaces";
import React from "react";

export const PageCreate: React.FC<{ clinicID?: BaseKey }> = ({ clinicID }) => {
    const invalidate = useInvalidate();

    const { saveButtonProps, getInputProps, errors } = useForm({
        refineCoreProps: {
            action: "create",
            resource: "clinicService",
            onMutationSuccess: (data, variables, context) => {
                invalidate({
                    resource: "clinic",
                    invalidates: ["all"],
                });
            },
        },
        initialValues: {
            clinicID: clinicID,
            serviceID: "",
            showPricing: true,
            priceCurrency: "EUR",
            priceMinValue: 0,
            priceMaxValue: 0,
            details: "",
        },
        transformValues: (values) => ({
            clinicID: clinicID,
            serviceID: values.serviceID,
            showPricing: values.showPricing,
            priceCurrency: values.priceCurrency,
            priceMinValue: values.priceMinValue,
            priceMaxValue: values.priceMaxValue,
            details: values.details,
        }),
        validate: {
            serviceID: (value) => (value === "" ? "Service is required" : null),
            clinicID: (value) => (value === "" ? "Clinic is required" : null),
            priceMinValue: (value, values) => (values.showPricing && values.priceMinValue <= 0 ? "Need at least minimum price" : null),
            priceMaxValue: (value, values) => {
                if (!values.showPricing) return null;
                if (values.priceMinValue > 0 && values.priceMaxValue > 0 && values.priceMinValue >= values.priceMaxValue) {
                    return "Maximum price must be greater than minimum price";
                }
                return null;
            },
            // isVisible: (value) =>
            //     value.length <= 0 ? "Status is required" : null,
            // parent: {
            //     id: (value) =>
            //         value.length <= 0 ? "Category is required" : null,
            // },
            // content: (value) =>
            //     value.length < 10 ? "Too short content" : null,
        },
    });

    const { queryResult } = useSelect<IService>({
        resource: "service",
        filters: [
            {
                field: "isVisible",
                value: true,
                operator: "eq",
            }
        ],
        // onSearch: (value) => [
        //     {
        //         field: "title.en",
        //         operator: "contains",
        //         value,
        //     },
        // ],
        meta: {
            fields: [
                "id",
                "title",
            ],
        },
    });

    const serviceOptions = queryResult.data?.data.map((item) => ({
        label: item.title.en ?? item.id,
        value: item.id,
    }));

    return (
        <Create
            saveButtonProps={saveButtonProps}
            headerProps={{ hidden: true }}
        >
            <form>
                <Text mt={8} weight={500} size="sm">
                    Show Pricing
                </Text>
                <Switch
                    size="md"
                    labelPosition="left"
                    {...getInputProps('showPricing', { type: 'checkbox' })}
                />
                <Select
                    mt={8}
                    label="Service"
                    placeholder="Pick one"
                    required
                    withinPortal
                    searchable
                    {...getInputProps("serviceID")}
                    data={serviceOptions ?? []}
                />

                <Select
                    mt={8}
                    label="Currency"
                    required
                    withinPortal
                    defaultValue={"EUR"}
                    {...getInputProps("priceCurrency")}
                    data={[
                        { label: "EUR", value: "EUR" },
                        { label: "USD", value: "USD" },
                    ]}
                />

                <NumberInput
                    label="Minimum Price"
                    defaultValue={0}
                    precision={2}
                    min={0}
                    step={0.05}
                    max={50000}
                    {...getInputProps("priceMinValue")}
                />

                <NumberInput
                    label="Maximum Price"
                    defaultValue={0}
                    precision={2}
                    min={0}
                    step={0.05}
                    max={100000}
                    {...getInputProps("priceMaxValue")}
                />

                <Textarea
                    mt={8}
                    label="Details"
                    placeholder="Details"
                    {...getInputProps("details")}
                />
                {errors.content && (
                    <Text mt={2} weight={500} size="xs" color="red">
                        {errors.content}
                    </Text>
                )}
            </form>
        </Create>
    );
};
