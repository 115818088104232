import { useParsed } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/mantine";
import { MultiSelect, TextInput, Text, Textarea, Paper, Switch } from "@mantine/core";

import { IFeature, IFeatureGroup } from "pages/interfaces";
import React from "react";

export const PageEdit = () => {
    const { id } = useParsed();

    const {
        getInputProps,
        saveButtonProps,
        setValues,
        errors,
        refineCore: { queryResult, formLoading },
    } = useForm<IFeature>({
        refineCoreProps: {
            id,
            invalidates: ["all"],
            meta: {
                fields: [
                    "id",
                    "title",
                    "details",
                    {
                        "categories": [
                            "id"
                        ]
                    },
                    // {
                    //     "category": [
                    //         {
                    //             edges: [
                    //                 {
                    //                     node: ["id"],
                    //                 }
                    //             ]
                    //         }
                    //     ]
                    // },
                ],
            },
            queryOptions: {
                onSuccess(data) {
                    const result = data?.data as IFeature;
                    const values = result.categories.map((item) => (item.id))
                    setValues({ categoryIDs: values });
                },
            }
        },
        initialValues: {
            title: "",
            details: "",
            categoryIDs: [],
        },
        // transformValues: (values) => ({
        //     title: values.title,
        //     details: values.details,
        //     categoryIDs: values.categoryIDs,
        // }),
        validate: {
            title: (value: string) => (value.length < 2 ? "Title too short" : null),
        },
    });

    // queryResult?.isFetched
    // const { isLoading } = queryResult;
    // const serviceData = queryResult?.data?.data as IFeature;


    const { queryResult: featureGroupQueryResult } = useSelect<IFeatureGroup>({
        resource: "featureGroup",
        // onSearch: (value) => [
        //     {
        //         field: "title.en",
        //         operator: "contains",
        //         value,
        //     },
        // ],
        meta: {
            fields: [
                "id",
                "title",
            ],
        },
    });

    const groupOptions = featureGroupQueryResult.data?.data.map((item) => ({
        label: item.title ?? item.id,
        value: item.id,
    }));

    return <Edit
        saveButtonProps={saveButtonProps}
    >
        <form>
            <TextInput
                mt={8}
                label="Title"
                placeholder="Title"
                withAsterisk
                {...getInputProps("title")}
            />
            {!formLoading && (
                <MultiSelect
                    mt={8}
                    label="Groups"
                    placeholder="Pick groups"
                    withinPortal
                    // searchable
                    clearable
                    maxDropdownHeight={200}
                    {...getInputProps("categoryIDs")}
                    // value={!isDirty("categoryIDs") ?? serviceData?.categories.map((item) => (item.id))}
                    data={groupOptions ?? []}
                />
            )}
            <Textarea
                mt={8}
                label="Details"
                placeholder="Details"
                {...getInputProps("details")}
            />
            {errors.content && (
                <Text mt={2} weight={500} size="xs" color="red">
                    {errors.content}
                </Text>
            )}
        </form>
    </Edit>;
};
