import { ActionIcon, Group, Text, } from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from '@tabler/icons';

export type PaginationProps = {
    total?: number
    hasNext?: boolean
    hasPrevious?: boolean
    isLoading?: boolean
    onClickNext?: () => void
    onClickPrevious?: () => void
};

export const Pagination: React.FC<PaginationProps> = ({ total, hasNext, hasPrevious, isLoading, onClickNext, onClickPrevious }) => {

    return (
        <Group style={{ marginTop: 20 }} position="right">
            <Text c="dimmed" fz="xs">Total: {total || 0}</Text>
            <ActionIcon
                variant="outline"
                disabled={!hasPrevious}
                loading={isLoading}
                onClick={onClickPrevious}
            >
                <IconArrowLeft />
            </ActionIcon>
            <ActionIcon
                variant="outline"
                disabled={!hasNext}
                loading={isLoading}
                onClick={onClickNext}
            >
                <IconArrowRight />
            </ActionIcon>
        </Group>
    )
}
