import { ActionIcon, Group, Text, useMantineTheme } from "@mantine/core";
import { Dropzone, DropzoneProps, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { BaseKey, useInvalidate } from "@refinedev/core";
import { IconPhoto, IconUpload, IconX } from '@tabler/icons';
import axios from "axios";
import * as ENV from "environment";
import React, { useState } from "react";

interface FileWithURL extends FileWithPath {
    url?: string;
}

export const PageCreate: React.FC<{ entityID?: BaseKey, entity: string; }> = ({ entityID, entity }) => {
    const theme = useMantineTheme();
    const invalidate = useInvalidate();

    const [files, setFiles] = useState<FileWithURL[]>([]);
    const [isUploadLoading, setIsUploadLoading] = useState(false);

    const url = `${ENV.REST_API}/gallery-upload`;

    const handleOnDrop = (files: FileWithPath[]) => {
        try {
            setIsUploadLoading(true);

            files.map(async (file) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("entity", entity);
                formData.append("entityID", entityID as string);

                const res = await axios.post<{ url: string }>(
                    url,
                    formData,
                    {
                        withCredentials: true,
                        // headers: {
                        //     "Access-Control-Allow-Origin": "*",
                        // },
                    },
                );

                invalidate({
                    resource: "service",
                    invalidates: ["all"],
                });

                setFiles(
                    (prev) =>
                        [
                            ...prev,
                            { url: res.data.url, ...file },
                        ] as FileWithURL[],
                );
            });

            setIsUploadLoading(false);
        } catch (error) {
            setIsUploadLoading(false);
        }
    };

    return <Dropzone
        accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
        onDrop={handleOnDrop}
        maxSize={3 * 1024 ** 2}
        loading={isUploadLoading}
    >
        <Group position="center" spacing="xl" style={{ minHeight: 150, pointerEvents: 'none' }}>
            <Dropzone.Accept>
                <IconUpload
                    size={50}
                    stroke={1.5}
                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                />
            </Dropzone.Accept>
            <Dropzone.Reject>
                <IconX
                    size={50}
                    stroke={1.5}
                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                />
            </Dropzone.Reject>
            <Dropzone.Idle>
                <IconPhoto size={50} stroke={1.5} />
            </Dropzone.Idle>

            <div style={{ textAlign: "center" }}>
                <Text size="md" inline>
                    Drag images here or click to select files
                </Text>
                <Text size="xs" color="dimmed" inline mt={7}>
                    Attach as many files as you like, each file should not exceed 5mb
                </Text>
            </div>
        </Group>
    </Dropzone>;
};
