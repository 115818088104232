import { NumberInput, Paper, Select, Switch, Text, Textarea, Title } from "@mantine/core";
import { useGetToPath, useGo, useParsed, useResource } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/mantine";

import { DefaultTitleStyle } from "pages/defaults";
import { IClinicService } from "pages/interfaces";
import React from "react";

export const PageEdit = () => {
    const { id } = useParsed();

    const { resource } = useResource("clinic");
    const getToPath = useGetToPath();
    const go = useGo();

    const {
        getInputProps,
        saveButtonProps,
        setFieldValue,
        errors,
        refineCore: { queryResult },
    } = useForm({
        refineCoreProps: {
            id,
            resource: "clinicService",
            invalidates: ["all"],
            meta: {
                fields: [
                    "id",
                    "showPricing",
                    "priceCurrency",
                    "priceMinValue",
                    "priceMaxValue",
                    "details",
                    {
                        "clinic": [
                            "id",
                            "title",
                        ]
                    },
                    {
                        "service": [
                            "id",
                            "title",
                        ]
                    },
                ],
            },
            queryOptions: {
                onSuccess(data) {
                },
            }
        },
        initialValues: {
            showPricing: true,
            priceCurrency: "EUR",
            priceMinValue: 0,
            priceMaxValue: 0,
            details: "",
        },
        transformValues: (values) => ({
            showPricing: values.showPricing,
            priceCurrency: values.priceCurrency,
            priceMinValue: values.priceMinValue,
            priceMaxValue: values.priceMaxValue,
            details: values.details,
        }),
        validate: {
            priceMinValue: (value, values) => (values.showPricing && values.priceMinValue <= 0 ? "Need at least minimum price" : null),
            priceMaxValue: (value, values) => {
                if (!values.showPricing) return null;
                if (values.priceMinValue > 0 && values.priceMaxValue > 0 && values.priceMinValue >= values.priceMaxValue) {
                    return "Maximum price must be greater than minimum price";
                }
                return null;
            },
        },
    });

    // queryResult?.isFetched
    // const { isLoading } = queryResult;
    const record = queryResult?.data?.data as IClinicService;


    return <Edit
        saveButtonProps={saveButtonProps}
        deleteButtonProps={{
            onSuccess: () => {
                go({
                    to: getToPath({
                        resource: resource,
                        action: "edit",
                        meta: {
                            id: record?.clinic.id,
                        },
                    }),
                    type: "replace",
                });
            }
        }}
    >
        <form>
            <Title {...DefaultTitleStyle} mt="md">Clinic</Title>
            <Text>{record?.clinic.title.en}</Text>

            <Title {...DefaultTitleStyle} mt="md">Service</Title>
            <Text>{record?.service.title.en}</Text>


            <Text mt={8} weight={500} size="sm">
                Show Pricing
            </Text>
            <Switch
                size="md"
                labelPosition="left"
                {...getInputProps('showPricing', { type: 'checkbox' })}
            />

            <Select
                mt={8}
                label="Currency"
                required
                withinPortal
                defaultValue={"EUR"}
                {...getInputProps("priceCurrency")}
                data={[
                    { label: "EUR", value: "EUR" },
                    { label: "USD", value: "USD" },
                ]}
            />

            <NumberInput
                label="Minimum Price"
                defaultValue={0}
                precision={2}
                min={0}
                step={0.05}
                max={50000}
                {...getInputProps("priceMinValue")}
            />

            <NumberInput
                label="Maximum Price"
                defaultValue={0}
                precision={2}
                min={0}
                step={0.05}
                max={100000}
                {...getInputProps("priceMaxValue")}
            />

            <Textarea
                mt={8}
                label="Details"
                placeholder="Details"
                {...getInputProps("details")}
            />

            {errors.content && (
                <Text mt={2} weight={500} size="xs" color="red">
                    {errors.content}
                </Text>
            )}
        </form>
    </Edit>;
};
