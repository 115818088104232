import { ActionIcon, Avatar, Badge, Blockquote, Button, Card, CopyButton, Divider, Group, Image, Modal, SimpleGrid, Text, Title, UnstyledButton } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { useParsed, useShow } from "@refinedev/core";
import { Show } from "@refinedev/mantine";
import { IconDownload, IconMessageDots, IconZoomIn } from '@tabler/icons';
import { BadgeAddon, BadgeService, CardImageGrid, MaskedEmail } from "components/micro";
import * as ENV from "environment";
import * as React from "react";

import { DefaultTitleStyle } from "pages/defaults";
import { IRequest } from "pages/interfaces";

export const PageShow = () => {
    const { id } = useParsed();
    const [opened, { close, open, toggle }] = useDisclosure(false);
    const [imageURL, setImageURL] = React.useState('');

    const { queryResult } = useShow<IRequest>({
        id,
        resource: "request",
        meta: {
            fields: [
                "id",
                "type",
                "addons",
                "closed",
                "referralCode",
                "plannedFor",
                {
                    "images": [
                        "id",
                        "sized(width: 400 height: 200)",
                        "large: sized(width: 2000)",
                        "url"
                    ]
                },
                {
                    "services": [
                        "id",
                        "slug",
                        "title",
                    ]
                },
                {
                    "clinics": [
                        "id",
                        "slug",
                        "title",
                    ]
                },
                {
                    "owner": [
                        "name",
                        "signedProfilePhoto",
                        "firstName",
                        "lastName",
                        "verifiedEmail",
                    ]
                },
                "notes",
                "createTime",
            ],
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    function showImage(url: string) {
        setImageURL(url);
        open();
    };

    return (
        <>
            <Modal opened={opened} onClose={close} centered size="80%">
                <Image
                    withPlaceholder
                    src={imageURL}
                />
            </Modal>

            <Show isLoading={isLoading}>
                <UnstyledButton component="a" href={`mailto:${record?.owner.verifiedEmail as string}`}>
                    <Group>
                        <Avatar src={record?.owner.signedProfilePhoto} alt={record?.owner.name} size={40} color="blue">{record?.owner.firstName[0]}{record?.owner.lastName[0]}</Avatar>
                        <div>
                            <Text>{record?.owner.name}</Text>
                            {record?.owner && <Text size="xs" color="dimmed"><MaskedEmail email={record?.owner.verifiedEmail as string} maskDomain /></Text>}
                        </div>
                    </Group>
                </UnstyledButton>

                <Title pt="md" {...DefaultTitleStyle}>Id</Title>
                <CopyButton value={record?.id as string}>
                    {({ copied, copy }) => (
                        <Button size="xs" compact radius="xl" color={copied ? 'lime' : ''} onClick={copy}>
                            {record?.id}
                        </Button>
                    )}
                </CopyButton>

                <Title {...DefaultTitleStyle} mt="md">Type</Title>
                <BadgeService serviceType={record?.type as string} />

                <Title {...DefaultTitleStyle} mt="md">Status</Title>
                <Text>{record?.closed ? <Badge color="cyan">Closed</Badge> : <Badge color="pink">Open</Badge>}</Text>

                {
                    (record && record?.images!.length > 0) &&
                    <>
                        <Title {...DefaultTitleStyle} mt="md" mb="sm">Photos</Title>

                        <SimpleGrid
                            cols={5}
                            breakpoints={[
                                { maxWidth: 'xl', cols: 4, spacing: 'xl' },
                                { maxWidth: 'lg', cols: 4, spacing: 'lg' },
                                { maxWidth: 'md', cols: 3, spacing: 'md' },
                                { maxWidth: 'sm', cols: 2, spacing: 'sm' },
                                { maxWidth: 'xs', cols: 1, spacing: 'sm' },
                            ]}
                        >
                            {(record?.images! as any).map((item: any) => (

                                <CardImageGrid
                                    key={item.id}
                                    image={item.sized}
                                    bottomBar={
                                        <Group position="apart" spacing="xs">
                                            <ActionIcon
                                                variant="transparent"
                                                loading={isLoading}
                                                onClick={() => showImage(item.large)}
                                            >
                                                <IconZoomIn />
                                            </ActionIcon>
                                            <ActionIcon
                                                component="a"
                                                href={item.large}
                                                target="_blank"
                                                variant="transparent"
                                                loading={isLoading}
                                            >
                                                <IconDownload />
                                            </ActionIcon>
                                        </Group>
                                    }
                                />

                            ))}
                        </SimpleGrid>


                    </>
                }

                {
                    (record && record?.addons?.length > 0) &&
                    <>
                        <Title {...DefaultTitleStyle} mt="md">Addons</Title>
                        <Text>
                            {record?.addons?.map((item) => (
                                <BadgeAddon addonType={item} marginRight={5} />
                            ))}
                        </Text>
                    </>
                }

                {
                    (record && record?.services!.length > 0) &&
                    <>
                        <Title {...DefaultTitleStyle} mt="md">Services</Title>
                        <Text>
                            {record?.services?.map((item) => (
                                <Badge
                                    variant="dot"
                                    color="lime"
                                    style={{ marginRight: 5 }}
                                    component="a"
                                    href={`${ENV.BASE_URL}/services/${item.slug}`}
                                    target="_blank"
                                >
                                    {item.title.en}
                                </Badge>
                            ))}
                        </Text>
                    </>
                }

                {
                    (record && record?.clinics!.length > 0) &&
                    <>
                        <Title {...DefaultTitleStyle} mt="md">Clinics</Title>
                        <Text>
                            {record?.clinics?.map((item) => (
                                <Badge
                                    variant="dot"
                                    color="yellow"
                                    style={{ marginRight: 5 }}
                                    component="a"
                                    href={`${ENV.BASE_URL}/clinics/${item.slug}`}
                                    target="_blank"
                                >
                                    {item.title.en}
                                </Badge>
                            ))}
                        </Text>
                    </>
                }

                {
                    (record && record?.notes.length > 0) &&
                    <>
                        <Divider my="sm" variant="dotted" />
                        <Blockquote p="md" icon={<IconMessageDots size={24} />} cite={`– ${record?.owner.firstName} ${record?.owner.lastName}`}>
                            {record?.notes}
                        </Blockquote>
                    </>
                }
            </Show>
        </>
    );
};
