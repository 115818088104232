import { Create, useForm, useSelect } from "@refinedev/mantine";
import { Select, TextInput, Text, Textarea, Paper, Switch } from "@mantine/core";

import { IFeatureGroup } from "pages/interfaces";
import React from "react";

export const PageCreate: React.FC = () => {

    const { saveButtonProps, getInputProps, errors } = useForm({
        initialValues: {
            title: "",
            details: "",
        },
        transformValues: (values) => ({
            title: values.title,
            details: values.details,
        }),
        validate: {
            title: (value) => (value.length < 2 ? "Title too short" : null),
        },
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                    label="Title"
                    placeholder="Title"
                    withAsterisk
                    {...getInputProps("title")}
                />
                <Textarea
                    mt={8}
                    label="Details"
                    placeholder="Details"
                    {...getInputProps("details")}
                />
                {errors.content && (
                    <Text mt={2} weight={500} size="xs" color="red">
                        {errors.content}
                    </Text>
                )}
            </form>
        </Create>
    );
};
