import { Button, CopyButton, Group, Paper, Text, Title } from "@mantine/core";
import { useParsed, useShow } from "@refinedev/core";
import {
    Show
} from "@refinedev/mantine";

import { IconCheck, IconCopy } from '@tabler/icons';
import { DefaultTitleStyle } from "pages/defaults";
import { IFeatureGroup } from "pages/interfaces";

export const PageShow = () => {
    const { id } = useParsed();

    const { queryResult } = useShow<IFeatureGroup>({
        id,
        meta: {
            fields: [
                "id",
                "title",
                "details",
            ],
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return <Show isLoading={isLoading}>
        <Title {...DefaultTitleStyle} mt="md" >Id</Title>

        <CopyButton value={record?.id as string}>
            {({ copied, copy }) => (
                <Button size="xs" compact radius="xl" color={copied ? 'lime' : ''} onClick={copy}>
                    {record?.id}
                </Button>
            )}
        </CopyButton>

        <Title {...DefaultTitleStyle} mt="md" >Title</Title>
        <Text>{record?.title}</Text>

        <Title {...DefaultTitleStyle} mt="md" >Details</Title>
        <Text>{record?.details}</Text>
    </Show>;

};
