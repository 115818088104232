import { Loader, TextInput } from '@mantine/core';
import { HttpError, useCustom } from "@refinedev/core";
import { IconCheck, IconLink, IconX } from '@tabler/icons';
import * as ENV from "environment";
import { useEffect, useRef, useState } from 'react';
import slug from 'slug';
import { useDebouncedCallback } from 'use-debounce';

type SlugInputProps = {
    // status: CheckerState;
    status: (value: CheckerState) => void;
    entity: string;
    manuallyChanged: (value: boolean) => void;
    sourceValue: string;
    onSourceValueChange?: (value: string) => void;
    checkSlugUniqueness?: (slug: string) => Promise<void>;
    slugProps: React.ComponentProps<typeof TextInput>;
};

export type CheckerState = 'checking' | 'unique' | 'duplicate' | 'invalid';

export const SlugInput: React.FC<SlugInputProps> = ({
    status,
    entity,
    manuallyChanged,
    sourceValue,
    onSourceValueChange,
    checkSlugUniqueness,
    slugProps,
}) => {

    const [checkerStatus, setCheckerStatus] = useState<CheckerState>(slugProps.value ? 'checking' : 'unique');

    // const url = '/slug-control';
    const url = `${ENV.REST_API}/slug-control`;
    const { refetch } = useCustom<
        UniqueCheckResponse,
        HttpError,
        UniqueCheckRequestBody
    >({
        url,
        method: "post",
        config: {
            payload: {
                slug: slugProps.value as string,
                entity: entity,
            },
        },
        queryOptions: {
            enabled: false,
        },
        dataProviderName: "rest",
    });

    const DefaultCheckSlugUniqueness = async (slug: string) => {
        if (slug.length < 3) {
            updateCheckerStatus('invalid');
            return
        }

        updateCheckerStatus('checking');

        const { data } = await refetch();
        if (data && data.data.exists) {
            console.log('Slug already exists!');
            updateCheckerStatus('duplicate');
        } else {
            updateCheckerStatus('unique');
        }
    };

    const updateCheckerStatus = async (value: CheckerState) => {
        status(value)
        setCheckerStatus(value)
    }

    const debouncedCheckSlugUniqueness = useDebouncedCallback(
        (slug: string) => {
            if (checkSlugUniqueness)
                return checkSlugUniqueness(slug)
            return DefaultCheckSlugUniqueness(slug)
        },
        500
    );

    useEffect(() => {
        const slugValue = slug(slugProps.value as string);
        debouncedCheckSlugUniqueness(slugValue);
        // console.log(`Values: ${slugProps.value} - ${slugValue}`)
        // console.log(`STATUS: ${status}`)
    }, [debouncedCheckSlugUniqueness, slugProps.value]);

    const handleSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        manuallyChanged(true);
        slugProps.onChange?.(event);
        if (onSourceValueChange) {
            onSourceValueChange(value);
        }
    };

    return (
        <TextInput
            {...slugProps}
            disabled={checkerStatus === 'checking'}
            error={slugProps.error}
            // error={slugProps.error || error}
            label={slugProps.label || "Slug"}
            placeholder={slugProps.placeholder || "Slug"}
            value={slugProps.value ?? slug(sourceValue)}
            rightSection={<IconLink size={14} />}
            icon={checkerStatus === 'checking' ? <Loader size="sm" color="gray" /> : checkerStatus === 'unique' ? <IconCheck size={14} color="green" /> : <IconX size={14} color="red" />}
            onChange={handleSlugChange}
        />
    );
};

interface UniqueCheckResponse {
    exists: boolean;
}

interface UniqueCheckRequestBody {
    slug: string;
    entity: string;
}
