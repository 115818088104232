import { ActionIcon, Button, Group, Popover, Text } from "@mantine/core";
import {
  pickNotDeprecated, useCan, useCustomMutation, useMutationMode, useResource, useTranslate, useWarnAboutChange
} from "@refinedev/core";
import type { DeleteButtonProps } from "@refinedev/mantine";
import { mapButtonVariantToActionIconVariant } from "@refinedev/mantine";
import { IconX } from "@tabler/icons";
import React, { useState } from "react";

/**
 * `<RemoveButton>` uses Mantine {@link https://mantine.dev/core/button/ `<Button>`} and {@link https://mantine.dev/core/modal/ `<Modal>`} components.
 * When you try to delete something, a dialog modal shows up and asks for confirmation. When confirmed it executes the `useDelete` method provided by your `dataProvider`.
 *
 * @see {@link https://refine.dev/docs/api-reference/mantine/components/buttons/delete-button} for more details.
 */
export const RemoveButton: React.FC<DeleteButtonProps> = ({
  resource: resourceNameFromProps,
  resourceNameOrRouteName,
  recordItemId,
  onSuccess,
  mutationMode: mutationModeProp,
  children,
  successNotification,
  errorNotification,
  hideText = false,
  accessControl,
  meta,
  metaData,
  dataProviderName,
  confirmTitle,
  confirmOkText,
  confirmCancelText,
  svgIconProps,
  ...rest
}) => {
  const accessControlEnabled = accessControl?.enabled ?? true;
  const hideIfUnauthorized = accessControl?.hideIfUnauthorized ?? false;
  const translate = useTranslate();

  const { id, resource } = useResource(
    resourceNameFromProps ?? resourceNameOrRouteName
  );

  const { mutationMode: mutationModeContext } = useMutationMode();

  const mutationMode = mutationModeProp ?? mutationModeContext;

  const { mutate, isLoading, variables } = useCustomMutation();

  const { data } = useCan({
    resource: resource?.name,
    action: "delete",
    params: { id: recordItemId ?? id, resource },
    queryOptions: {
      enabled: accessControlEnabled,
    },
  });

  const [opened, setOpened] = useState(false);

  const onConfirm = () => {
    setWarnWhen(false);
    setOpened(false);
    mutate(
      {
        url: "",
        method: "post",
        meta: meta,
        values: {},
      },
      {
        onSuccess: onSuccess
      }
    );
  };

  const { variant, styles, ...commonProps } = rest;

  const { setWarnWhen } = useWarnAboutChange();

  if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
    return null;
  }

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      withArrow
      withinPortal
      disabled={
        typeof rest?.disabled !== "undefined"
          ? rest.disabled
          : data?.can === false
      }
    >
      <Popover.Target>
        {hideText ? (
          <ActionIcon
            // color="red"
            onClick={() => setOpened((o) => !o)}
            disabled={isLoading || data?.can === false}
            className="refine-delete-button"
            {...(variant
              ? {
                variant:
                  mapButtonVariantToActionIconVariant(
                    variant,
                  ),
              }
              : { variant: "transparent" })}
            {...commonProps}
          >
            {children ?? <IconX size={18} {...svgIconProps} />}
          </ActionIcon>
        ) : (
          <Button
            color="red"
            variant="outline"
            onClick={() => setOpened((o) => !o)}
            disabled={isLoading || data?.can === false}
            leftIcon={<IconX size={18} {...svgIconProps} />}
            className="refine-delete-button"
            {...rest}
          >
            {children ?? translate("buttons.remove", "Remove")}
          </Button>
        )}
      </Popover.Target>
      <Popover.Dropdown py="xs">
        <Text size="sm" weight="bold">
          {confirmTitle ?? translate("buttons.confirm", "Are you sure?")}
        </Text>
        <Group position="center" noWrap spacing="xs" mt="xs">
          <Button onClick={() => setOpened(false)} variant="default" size="xs">
            {confirmCancelText ?? translate("buttons.cancel", "Cancel")}
          </Button>
          <Button color="red" onClick={onConfirm} autoFocus size="xs">
            {confirmOkText ?? translate("buttons.remove", "Remove")}
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};
