import { Box, Button, Collapse, Flex, Group, Modal, Paper, Select, SimpleGrid, Switch, Text, Textarea, TextInput } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { useInvalidate, useParsed } from "@refinedev/core";
import { Edit, SaveButton, useForm, useSelect } from "@refinedev/mantine";
import { IconEye, IconEyeOff, IconPhoto, IconTrash } from '@tabler/icons';
import { RemoveButton } from "components/buttons/remove";
import { CardImageGrid } from "components/micro";
import { EditorCore, EDITOR_JS_TOOLS } from 'editorjsTools';
import { createReactEditorJS } from 'react-editor-js';
import * as ImagesPages from "../images";

import { IPerson } from "pages/interfaces";
import React, { useState } from "react";

const ReactEditorJS = createReactEditorJS()

let editorValue = {}

export const PageEdit = () => {
    const { id } = useParsed();
    const [opened, { toggle }] = useDisclosure(false);
    const [drawerOpenedImages, setDrawerOpenedImages] = useState(false);

    const [getEditorOutput, setEditorOutput] = React.useState(editorValue)

    const editorInstance = React.useRef<null | EditorCore>(null);

    const handleInitialize = React.useCallback((instance: EditorCore) => {
        editorInstance.current = instance;
    }, [])

    const onEditorChange = React.useCallback((api: any) => {
        api.saver.save().then((outputData: any) => {
            setEditorOutput(outputData)
        });
    }, [])

    const invalidate = useInvalidate();

    const {
        getInputProps,
        saveButtonProps,
        setFieldValue,
        errors,
        refineCore: { queryResult, formLoading },
    } = useForm({
        refineCoreProps: {
            id,
            resource: "person",
            invalidates: ["all"],
            meta: {
                fields: [
                    "id",
                    "name",
                    "title",
                    "description",
                    "content",
                    "isVisible",
                    {
                        "images": [
                            "id",
                            "sized(width: 200 height: 200)",
                        ]
                    },
                ],
            },
            queryOptions: {
                onSuccess(data) {
                    // const { data: record } = data;
                    setEditorOutput(record?.content);
                },
            }
        },
        initialValues: {
            title: "",
            name: "",
            description: "",
            isVisible: true,
            content: "",
        },
        transformValues: (values) => ({
            title: values.title,
            description: values.description,
            name: values.name,
            isVisible: values.isVisible,
            content: getEditorOutput,
        }),
        validate: {
            name: (value) => (value.length < 5 ? "Name too short" : null),
            description: (value) => (value.length > 250 ? "Description too long" : null),
            // parent: {
            //     id: (value) =>
            //         value.length <= 0 ? "Category is required" : null,
            // },
            // content: (value) =>
            //     value.length < 10 ? "Too short content" : null,
        },
    });

    // queryResult?.isFetched
    // const { isLoading } = queryResult;
    const record = queryResult?.data?.data as IPerson;


    const DeleteImage: React.FC<{ id: string }> = ({
        id
    }) => {
        return <RemoveButton
            hideText
            size="xs"
            // variant="filled"
            // color="red"
            radius="sm"
            compact
            // uppercase
            svgIconProps={{ size: 12 }}
            meta={
                {
                    operation: "updatePerson",
                    variables: {
                        input: {
                            value: {
                                "removeImageIDs": [
                                    id
                                ],
                            },
                            type: "UpdatePersonInput",
                            required: true
                        },
                        id: {
                            type: "ID",
                            value: record?.id,
                            required: true,
                        },
                    },
                    fields: ["id"],
                }
            }
            onSuccess={(data) => {
                invalidate({
                    resource: "person",
                    invalidates: ["detail"],
                    id: data.data?.id,
                });

                // setDrawerOpened(false);
            }
            }
        ><IconTrash /></RemoveButton>
    }

    return (
        <>
            {/* Gallery Drawer */}
            <Modal
                opened={drawerOpenedImages}
                onClose={() => setDrawerOpenedImages(false)}
                title="Edit Gallery"
                padding="xl"
                size="xl"
            >

                <SimpleGrid
                    cols={2}
                    breakpoints={[
                        { maxWidth: 'sm', cols: 2, spacing: 'sm' },
                        { maxWidth: 'xs', cols: 1, spacing: 'sm' },
                    ]}
                >
                    <ImagesPages.PageCreate entityID={id} entity="person" />


                    {record?.images?.map((item: any) => (

                        <CardImageGrid
                            key={item.id}
                            image={item.sized}
                            bottomBar={
                                <Group position="apart" spacing="xs">
                                    <DeleteImage id={item.id} />
                                </Group>
                            }
                        />

                    ))}

                </SimpleGrid>

            </Modal>


            {/* Main Form */}
            <Edit
                saveButtonProps={saveButtonProps}
            >
                <form>

                    <TextInput
                        mt={8}
                        label="Title"
                        placeholder="Title"
                        {...getInputProps("title")}
                    />
                    <TextInput
                        mt={8}
                        label="Name"
                        placeholder="Name"
                        withAsterisk
                        {...getInputProps("name")}
                    />
                    <Textarea
                        mt={8}
                        label="Description"
                        placeholder="Description"
                        {...getInputProps("description")}
                    />

                    <Text mt={8} weight={500} size="sm">
                        Visible
                    </Text>
                    <Switch
                        size="md"
                        labelPosition="left"
                        {...getInputProps('isVisible', { type: 'checkbox' })}
                    />

                    <Flex
                        mt="lg"
                        gap="sm"
                        justify="flex-start"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                    >
                        <Button
                            size="sm"
                            variant="light"
                            compact
                            rightIcon={<IconPhoto size={14} />}
                            onClick={() => setDrawerOpenedImages(true)}
                        >
                            Gallery
                        </Button>

                        <Button
                            size="sm"
                            variant="light"
                            compact
                            rightIcon={opened ? <IconEyeOff size={14} /> : <IconEye size={14} />}
                            onClick={toggle}
                        >
                            About
                        </Button>
                    </Flex>
                    <Collapse in={opened} mt="md">
                        <Paper shadow="xs" p="md" withBorder>
                            {record?.content && !formLoading && <ReactEditorJS
                                onChange={onEditorChange}
                                onReady={() => console.log('Start!')}
                                onInitialize={handleInitialize}
                                tools={EDITOR_JS_TOOLS}
                                placeholder="Type something..."
                                defaultValue={record?.content}
                            />}
                        </Paper>
                    </Collapse>
                    {errors.content && (
                        <Text mt={2} weight={500} size="xs" color="red">
                            {errors.content}
                        </Text>
                    )}
                </form>
            </Edit>
        </>
    );
};
