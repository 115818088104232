import { useGetIdentity, useGetLocale, useSetLocale } from "@refinedev/core";
import {
  ActionIcon,
  Avatar,
  Group,
  Header as MantineHeader,
  Title,
  useMantineColorScheme,
  useMantineTheme,
  Menu,
  Flex,
} from "@mantine/core";
import { IconSun, IconMoonStars, IconLanguage } from "@tabler/icons";
import {
  RefineThemedLayoutV2HeaderProps,
  HamburgerMenu,
} from "@refinedev/mantine";

import i18n from "i18n";

type IUser = {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  const { data: user } = useGetIdentity<IUser>();

  const changeLanguage = useSetLocale();
  const locale = useGetLocale();
  const currentLocale = locale();

  const theme = useMantineTheme();

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  const borderColor = dark ? theme.colors.dark[6] : theme.colors.gray[2];

  return (
    <MantineHeader
      zIndex={199}
      height={64}
      py="sm"
      px="sm"
      sx={{
        borderBottom: `1px solid ${borderColor}`,
      }}
    >
      <Flex justify="space-between">
        <HamburgerMenu />
        <Group>
          {i18n.languages?.length > 1 &&
            <Menu shadow="md">
              <Menu.Target>
                <ActionIcon variant="outline">
                  <IconLanguage size={18} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                {[...(i18n.languages ?? [])].sort().map((lang: string) => (
                  <Menu.Item
                    key={lang}
                    onClick={() => {
                      changeLanguage(lang);
                    }}
                    value={lang}
                    color={lang === currentLocale ? "primary" : undefined}
                    icon={
                      <Avatar
                        src={`/images/flags/${lang}.svg`}
                        size={18}
                        radius="lg"
                      />
                    }
                  >
                    {lang === "en" ? "English" : "Türkçe"}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          }

          <ActionIcon
            variant="outline"
            color={dark ? "yellow" : "primary"}
            onClick={() => toggleColorScheme()}
            title="Toggle color scheme"
          >
            {dark ? <IconSun size={18} /> : <IconMoonStars size={18} />}
          </ActionIcon>

          {(user?.first_name || user?.avatar) && (
            <Group spacing="xs">
              {user?.first_name && <Title order={6}>{user?.first_name}</Title>}
              <Avatar src={user?.avatar} alt={user?.first_name} radius="xl" />
            </Group>
          )}
        </Group>
      </Flex>
    </MantineHeader>
  );
};
