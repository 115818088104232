import * as ENV from 'environment'

// import Paragraph from '@editorjs/paragraph'
// import Embed from '@editorjs/embed'
// import Table from '@editorjs/table'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
// import Code from '@editorjs/code'
// import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'

import { OutputData } from '@editorjs/editorjs';

export interface EditorCore {
  destroy(): Promise<void>;
  clear(): Promise<void>;
  save(): Promise<OutputData>;
  render(data: OutputData): Promise<void>;
  get dangerouslyLowLevelInstance(): any | null;
}

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  // embed: Embed,
  // table: Table,
  list: List,
  warning: Warning,
  // code: Code,
  // linkTool: LinkTool,
  image: {
    class: Image,
    config: {
      actions: [
        {
          name: 'isHero',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> < path stroke="none" d="M0 0h24v24H0z" fill="none" ></> <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path> </svg >',
          title: 'Hero Image',
          toggle: true,
        }
      ],
      uploader: {
        /**
         * Upload file to the server and return an uploaded image data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
        */
        uploadByFile(file: File) {
          const data = new FormData();
          data.append('name', 'image');
          data.append('file', file);

          return fetch(ENV.IMAGE_UPLOAD_ENDPOINT, {
            method: 'POST',
            body: data,
            credentials: 'include'
          })
            .then((res) => {
              return res.json();
            })
            .catch((res) => {
              return {
                success: 0,
              };
            });
        },
      },
    },
  },
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
}
