import React from "react";

import { ActionIcon, Badge, CopyButton, Group, Pagination, Space, Table, Text, Tooltip } from "@mantine/core";
import { DateField, EditButton, List, ShowButton } from "@refinedev/mantine";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";

import { IconCheck, IconCopy } from '@tabler/icons';

import { IRequest } from "pages/interfaces";

export const PageConsultationList: React.FC = () => {
    const columns = React.useMemo<ColumnDef<IRequest>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                size: 999,
                cell: function render({ getValue }) {
                    return (
                        <Group spacing="xs" position="left">
                            <CopyButton value={getValue() as string} timeout={2000}>
                                {({ copied, copy }) => (
                                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                        <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                                            {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                                        </ActionIcon>
                                    </Tooltip>
                                )}
                            </CopyButton>
                            <Space w="xs" />
                            <ShowButton
                                size="md"
                                recordItemId={getValue() as string}
                                hideText
                            />
                            {/*                            <EditButton
                                size="md"
                                recordItemId={getValue() as string}
                                hideText
                            />
*/}                        </Group>

                    );
                }
            },
            {
                id: "name",
                header: "Name",
                accessorKey: "owner.name",
            },
            {
                id: "visible",
                header: "Status",
                accessorKey: "closed",
                cell: function render({ getValue }) {

                    return getValue() as boolean ? <Badge color="cyan">Closed</Badge> : <Badge color="pink">Open</Badge>;

                },
            },
            {
                id: "createdAt",
                header: "Created At",
                accessorKey: "createTime",
                cell: function render({ getValue }) {
                    return (
                        <DateField value={getValue() as string} format="LLL" />
                    );
                },
            },
        ],
        [],
    );

    const {
        getHeaderGroups,
        getRowModel,
        refineCore: { setCurrent, pageCount, current },
    } = useTable({
        columns,
        refineCoreProps: {
            sorters: {
                initial: [
                    {
                        field: "id",
                        order: "asc",
                    }
                ],
            },
            filters: {
                permanent: [
                    {
                        field: "type",
                        value: "SERVICE_CONSULTATION",
                        operator: "eq",
                    }
                ],
            },
            resource: "request",
            meta: {
                fields: [
                    "id",
                    "closed",
                    "createTime",
                    {
                        "owner": ["id", "name"],
                    },
                ],
            },
        },
    });

    return (
        <List>
            <Table>
                <thead>
                    {getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    style={{
                                        minWidth:
                                            header.getSize() === 999 ? "200px" : undefined,
                                        width:
                                            header.getSize() === 999 ? "20%" : undefined,
                                    }}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    );
};
