import {
  useLogin,
  useTranslate,
} from "@refinedev/core";
import {
  Paper,
  Group,
  Anchor,
  Button,
  Container,
  Flex,
} from "@mantine/core";

import { IconLock, } from "@tabler/icons";

export const Login = () => {
  const t = useTranslate();

  const { mutate } = useLogin();

  return (

    <Flex
      align="center"
      direction="row"
      mih={"100vh"}
    >
      <Container size={420} my={40}>
        <Paper radius="md" p="xl" shadow="md" withBorder>
          <Group position="center" mt="lg" mb="xl">
            <Anchor
              href="/"
            >
              <img height="48" src="/images/logo.svg" alt="Vituras Backoffice" />
            </Anchor>

          </Group>

          <Group grow mb="md" mt="md">
            <Button
              fullWidth
              size="lg"
              radius="xl"
              leftIcon={<IconLock />}
              // loading={authProvider.isLoading()}
              // onClick={() => authProvider.login('dummy')}
              onClick={() => mutate('dummy')}
            >
              {t("pages.login.signin")}
            </Button>
          </Group>

          <Group position="center" mt="xl">
            <Anchor
              href="https://id.vituras.com/register"
              color="dimmed"
              size="xs"
            >
              Don't have an account? <b>Register</b>
            </Anchor>
          </Group>
        </Paper>
      </Container>
    </Flex>
  );
};
