import React, { useState } from "react";

import { ActionIcon, Badge, Card, Group, Image, LoadingOverlay, SimpleGrid, Space, Text, Tooltip } from "@mantine/core";
import { useTable } from "@refinedev/core";
import { DateField, DeleteButton, EditButton, List, ShowButton } from "@refinedev/mantine";
import { Pagination } from "components/pagination";
import { Cursors } from "relay/data-provider";

import { IImage } from "pages/interfaces";

export const PageList: React.FC = () => {
    const [cursors, setCursors] = useState<Cursors>({ first: 12, })
    const [perPage, setPerPage] = useState<number>(12)

    const {
        tableQueryResult, setFilters, current, setCurrent,
    } = useTable<IImage>({
        resource: "image",
        filters: {
            permanent: [
                {
                    field: "fromEditor",
                    value: false,
                    operator: "eq",
                }
            ],
        },
        meta: {
            fields: [
                "id",
                "url",
                "sized(width: 400 height: 200)",
                "title",
                "createTime",
                {
                    "owner": ["id", "name"],
                },
            ],
            cursors,
        },
    });


    const { data, isLoading } = tableQueryResult;

    // const data = queryData?.data;
    // const data = tableQueryResult.data;

    return (
        <List
            canCreate={false}
        >
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <SimpleGrid
                cols={4}
                breakpoints={[
                    { maxWidth: 'md', cols: 3, spacing: 'md' },
                    { maxWidth: 'sm', cols: 2, spacing: 'sm' },
                    { maxWidth: 'xs', cols: 1, spacing: 'sm' },
                ]}
            >
                {tableQueryResult.data?.data.map((item) => (

                    <Card key={item.id} shadow="sm" radius="md" withBorder>
                        <Card.Section>
                            <Image
                                sx={{ maxHeight: 200 }}
                                withPlaceholder
                                src={item.sized}
                                alt={item.url}
                            />
                        </Card.Section>

                        <Text fz="xs" mt="xs" ta="center">&nbsp;{item.title}&nbsp;</Text>

                        <Group position="apart" mt="xs" mb="xs">
                            <Badge size="xs" variant="light">
                                {item.owner?.name}
                            </Badge>
                            <DeleteButton size="md" recordItemId={item.id} hideText />
                        </Group>


                        {/* <Button variant="light" color="blue" fullWidth mt="md" radius="md">
                            Book classic tour now
                        </Button> */}
                    </Card>
                    // <Text key={item.id}>{item.id}</Text>
                ))}
            </SimpleGrid>
            <br />

            <Pagination
                total={data?.total}
                hasPrevious={Boolean(data?.pageInfo?.hasPreviousPage)}
                hasNext={Boolean(data?.pageInfo?.hasNextPage)}
                isLoading={isLoading}
                onClickPrevious={() => {
                    setCursors((ov) => ({
                        ...ov,
                        before: data?.pageInfo?.startCursor,
                        last: perPage,
                        after: undefined,
                        first: undefined,
                    }))
                }}
                onClickNext={() => {
                    setCursors((ov) => {
                        return {
                            ...ov,
                            after: data?.pageInfo?.endCursor,
                            first: perPage,
                            before: undefined,
                            last: undefined,
                        }
                    })
                }}
            />



        </List>
    );
};
