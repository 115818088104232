import * as ENV from "environment";

import {
  AuthBindings,
  Authenticated, Refine
} from "@refinedev/core";

import type { I18nProvider } from "@refinedev/core";

import {
  ErrorComponent,
  notificationProvider,
  RefineThemes,
  ThemedLayoutV2, ThemedSiderV2, ThemedTitleV2
} from "@refinedev/mantine";

import axios from "axios";

import { GraphQLClient } from "graphql-request";
import dataProvider from "./relay/data-provider";
// import dataProvider  from "@refinedev/simple-rest";
import {
  ColorScheme, ColorSchemeProvider, Divider, Global, MantineProvider
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { NotificationsProvider } from "@mantine/notifications";
import routerBindings, {
  CatchAllNavigate, NavigateToResource, UnsavedChangesNotifier
} from "@refinedev/react-router-v6";
import { default as restProvider } from "@refinedev/simple-rest";
import { CheckSession } from "pages/check_session";
import { Login } from "pages/login";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ViturasMark } from "./assets/icons";
import { Header } from "./components/header";
// import { DashboardComponent } from "pages/dashboard/dashboard";
import * as ClinicPages from "./pages/clinics";
import * as ClinicServicePages from "./pages/clinic_services";
import * as FeatureGroupPages from "./pages/featuregroups";
import * as FeaturePages from "./pages/features";
import * as ImagePages from "./pages/images";
import * as OrganizationPages from "./pages/organizations";
import * as StaffPages from "./pages/people";
import * as RequestPages from "./pages/requests";
import { Resources } from "./pages/resources";
import * as ServicePages from "./pages/services";
import * as UserPages from "./pages/users";
import * as VisitorPages from "./pages/visitors";

const axiosInstance = axios.create(
  {
    withCredentials: true,
  }
);

const client = new GraphQLClient(ENV.GRAPHQL_URL, {
  credentials: 'include',
  mode: 'cors',
});

const gqlDataProvider = dataProvider(client);

const authProvider: AuthBindings = {
  login: async () => {
    console.log('CHECKING FOR SESSION')
    const authResponse = await fetch(ENV.IDENTITY_ENDPOINT, {
      credentials: "include",
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (authResponse.ok) {
      const user = await authResponse.json();

      if (user) {
        localStorage.setItem("auth", JSON.stringify(user));
        return {
          success: true,
          redirectTo: "/",
        };
      }
      return {
        success: false,
        error: new Error("Login failed"),
      };
    } else {
      window.location.href = `${ENV.ID_URL}/login?return_to=${window.location.origin}/check-login`;

      return {
        success: false,
      }
    }
  },
  logout: async () => {
    localStorage.removeItem("auth");
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const user = localStorage.getItem("auth");

    if (user) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      logout: true,
      redirectTo: "/login",
      error: {
        message: "Check failed",
        name: "Unauthorized",
      },
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const user = localStorage.getItem("auth");

    if (user) {
      const { id, first_name, last_name, avatar } = JSON.parse(user);

      return { id, first_name, last_name, avatar };
    }

    return null;
  },
  onError: async (error) => {
    if (error["response"]["errors"][0]["message"] === "Access denied") {
      console.error("Auth error")
      localStorage.removeItem("auth");
      return {
        logout: true,
        redirectTo: "/login",
        error: {
          message: "Session expired",
          statusCode: 403,
        },
      };
    }
    console.error("Query error")
    return {};
  },
}

const Sider = () => {
  return (
    <ThemedSiderV2
      Title={({ collapsed }) => <ThemedTitleV2
        collapsed={collapsed}
        icon={<ViturasMark />}
        text="Backoffice"
      />}
      render={({ items, logout }) => {
        return (
          <>
            {items}
            <Divider my="sm" variant="dotted" />
            {logout}
          </>
        );
      }}
    />
  );
};

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });
  const { t, i18n } = useTranslation();

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const i18nProvider: I18nProvider = {
    translate: (key: string, options?: any) => t(key, options),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          withNormalizeCSS
          withGlobalStyles
          theme={{
            ...RefineThemes.Purple,
            colorScheme: colorScheme,
            colors: {
              brand: ['#fbf5ff', '#f5e9fe', '#ecd6fe', '#deb6fc', '#c987f9', '#bb68f4', '#a138e5', '#8b26ca', '#7524a5', '#601f84',]
            },
            primaryColor: 'brand',
            components: {
              Table: {
                defaultProps: { fontSize: 'sm' },
              }
            },
            // fontSizes: {
            //   xs: 10,
            //   sm: 12,
            //   md: 14,
            //   lg: 16,
            //   xl: 18,
            // },
            primaryShade: 5
          }}
        >
          <Global styles={{ body: { WebkitFontSmoothing: "auto" } }} />
          <NotificationsProvider position="top-right">
            <Refine
              dataProvider={{
                default: gqlDataProvider,
                rest: restProvider(ENV.REST_API, axiosInstance)
              }}
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              authProvider={authProvider}
              i18nProvider={i18nProvider}
              resources={Resources}
              options={{
                breadcrumb: false,
                disableTelemetry: true,
                syncWithLocation: false,
                warnWhenUnsavedChanges: true,
              }}
            >

              <Routes>
                {/* <Route
                  element={
                    <Authenticated>
                      <ThemedLayoutV2>
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route path="/auth/*" element={<ErrorComponent />} />
                </Route> */}

                <Route
                  element={
                    <Authenticated
                      fallback={<CatchAllNavigate to="/login" />}
                    >
                      <ThemedLayoutV2
                        Sider={() => <Sider />}
                        Header={() => <Header />}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route
                    index
                    element={<NavigateToResource resource="consultation" />}
                  />

                  {/* Consultations */}
                  <Route path="consultation">
                    <Route
                      index
                      element={<RequestPages.PageConsultationList />}
                    />
                    <Route
                      path="edit/:id"
                      element={<RequestPages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<RequestPages.PageShow />}
                    />
                  </Route>

                  {/* Requests */}
                  <Route path="request">
                    <Route
                      index
                      element={<RequestPages.PageList />}
                    />
                    <Route
                      path="edit/:id"
                      element={<RequestPages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<RequestPages.PageShow />}
                    />
                  </Route>

                  {/* Users */}
                  <Route path="user">
                    <Route
                      index
                      element={<UserPages.PageList />}
                    />
                    <Route
                      path="edit/:id"
                      element={<UserPages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<UserPages.PageShow />}
                    />
                  </Route>

                  {/* Visitors */}
                  <Route path="visitor">
                    <Route
                      index
                      element={<VisitorPages.PageList />}
                    />
                    <Route
                      path="show/:id"
                      element={<VisitorPages.PageShow />}
                    />
                  </Route>

                  {/* Clinic */}
                  <Route path="clinic">
                    <Route
                      index
                      element={<ClinicPages.PageList />}
                    />
                    <Route
                      path="create"
                      element={<ClinicPages.PageCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<ClinicPages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<ClinicPages.PageShow />}
                    />
                  </Route>

                  {/* ClinicService */}
                  <Route path="clinicservice">
                    <Route
                      path="edit/:id"
                      element={<ClinicServicePages.PageEdit />}
                    />
                  </Route>

                  {/* Staff */}
                  <Route path="staff">
                    <Route
                      index
                      element={<StaffPages.PageList />}
                    />
                    <Route
                      path="create"
                      element={<StaffPages.PageCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<StaffPages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<StaffPages.PageShow />}
                    />
                  </Route>

                  {/* Feature */}
                  <Route path="feature">
                    <Route
                      index
                      element={<FeaturePages.PageList />}
                    />
                    <Route
                      path="create"
                      element={<FeaturePages.PageCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<FeaturePages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<FeaturePages.PageShow />}
                    />
                  </Route>

                  {/* FeatureGroup */}
                  <Route path="featuregroup">
                    <Route
                      index
                      element={<FeatureGroupPages.PageList />}
                    />
                    <Route
                      path="create"
                      element={<FeatureGroupPages.PageCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<FeatureGroupPages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<FeatureGroupPages.PageShow />}
                    />
                  </Route>

                  {/* Service */}
                  <Route path="service">
                    <Route
                      index
                      element={<ServicePages.PageList />}
                    />
                    <Route
                      path="create"
                      element={<ServicePages.PageCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<ServicePages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<ServicePages.PageShow />}
                    />
                  </Route>

                  {/* Organization */}
                  <Route path="organization">
                    <Route
                      index
                      element={<OrganizationPages.PageList />}
                    />
                    <Route
                      path="create"
                      element={<OrganizationPages.PageCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<OrganizationPages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<OrganizationPages.PageShow />}
                    />
                  </Route>

                  {/* Image */}
                  <Route path="image">
                    <Route
                      index
                      element={<ImagePages.PageList />}
                    />
                    <Route
                      path="edit/:id"
                      element={<ImagePages.PageEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<ImagePages.PageShow />}
                    />
                  </Route>

                </Route>

                <Route
                  element={
                    <Authenticated fallback={<Outlet />}>
                      <NavigateToResource resource="visitor" />
                    </Authenticated>
                  }
                >
                  <Route path="/login" element={<Login />} />
                  <Route path="/check-login" element={<CheckSession />} />
                </Route>

                <Route
                  element={
                    <Authenticated>
                      <ThemedLayoutV2
                        Sider={() => <Sider />}
                        Header={() => <Header />}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
              </Routes>


              {/* <Routes>
                <Route
                  element={
                    <Authenticated
                      fallback={<CatchAllNavigate to="/login" />}
                    >
                      <ThemedLayoutV2>
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route index element={<DashboardComponent />} />

                </Route>

                <Route
                  element={
                    <Authenticated>
                      <ThemedLayoutV2>
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route path="*" element={<ErrorComponent />} />
                </Route>

              </Routes>
 */}
              <UnsavedChangesNotifier />
            </Refine>
          </NotificationsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </BrowserRouter>
  );
}

export default App;
