/* eslint no-use-before-define: 0 */
import {
    IconArchive, IconBandage, IconBrandGoogleFit, IconBuildingCommunity,
    IconCalendarEvent, IconCamera,
    IconClipboard, IconFileCheck,
    IconFileDescription, IconFirstAidKit, IconHeartHandshake, IconHomeHeart, IconId, IconMapPin, IconMoodSmile, IconSitemap, IconStethoscope, IconTicket, IconUsers
} from "@tabler/icons";

export const Resources = [
    {
        name: "requests",
        key: "requests",
        icon: <IconCalendarEvent />,
        options: {
            label: "Requests",
        }
    },
    {
        name: "consultation",
        options: {
            label: "Consultations",
        },
        list: "/consultation",
        show: "/consultation/show/:id",
        edit: "/consultation/edit/:id",
        meta: {
            parent: "requests",
            canDelete: true,
        },
        icon: <IconCamera />,
    },
    {
        name: "request",
        options: {
            label: "Other",
        },
        list: "/request",
        show: "/request/show/:id",
        edit: "/request/edit/:id",
        meta: {
            parent: "requests",
            canDelete: true,
        },
        icon: <IconClipboard />,
    },
    {
        name: "user",
        options: {
            label: "Users",
        },
        list: "/user",
        show: "/user/show/:id",
        edit: "/user/edit/:id",
        meta: {
            canDelete: false,
        },
        icon: <IconUsers />,
    },
    {
        name: "visitor",
        options: {
            label: "Visitors",
        },
        list: "/visitor",
        show: "/visitor/show/:id",
        meta: {
            canDelete: false,
        },
        icon: <IconId />,
    },

    {
        name: "clinics",
        key: "clinics",
        icon: <IconHeartHandshake />,
        options: {
            label: "Clinics",
        }
    },

    {
        name: "clinicService",
        key: "clinicservice",
        icon: <IconHeartHandshake />,
        options: {
            label: "Clinic Service",
        },
        edit: "/clinicservice/edit/:id",
        meta: {
            canDelete: true,
        },
    },

    {
        name: "clinic",
        options: {
            label: "Facilities",
        },
        list: "/clinic",
        create: "/clinic/create",
        show: "/clinic/show/:id",
        edit: "/clinic/edit/:id",
        meta: {
            parent: "clinics",
            canDelete: true,
        },
        icon: <IconStethoscope />,
    },
    {
        name: "person",
        options: {
            label: "Staff",
        },
        list: "/staff",
        show: "/staff/show/:id",
        edit: "/staff/edit/:id",
        create: "/staff/create",
        meta: {
            parent: "clinics",
            canDelete: true,
        },
        icon: <IconUsers />,
    },
    {
        name: "feature",
        options: {
            label: "Features",
        },
        list: "/feature",
        show: "/feature/show/:id",
        edit: "/feature/edit/:id",
        create: "/feature/create",
        meta: {
            parent: "clinics",
            canDelete: true,
        },
        icon: <IconFileCheck />,
    },
    {
        name: "featureGroup",
        options: {
            label: "Feature Groups",
        },
        list: "/featuregroup",
        show: "/featuregroup/show/:id",
        edit: "/featuregroup/edit/:id",
        create: "/featuregroup/create",
        meta: {
            parent: "clinics",
            canDelete: true,
        },
        icon: <IconFileDescription />,
    },
    // {
    //     name: "location",
    //     options: {
    //         label: "Locations",
    //     },
    //     list: "/location",
    //     create: "/location/create",
    //     show: "/location/show/:id",
    //     edit: "/location/edit/:id",
    //     meta: {
    //         canDelete: true,
    //     },
    //     icon: <IconMapPin />,
    // },
    {
        name: "service",
        options: {
            label: "Services",
        },
        list: "/service",
        create: "/service/create",
        show: "/service/show/:id",
        edit: "/service/edit/:id",
        meta: {
            canDelete: true,
        },
        icon: <IconBandage />,
    },
    {
        name: "organization",
        options: {
            label: "Organizations",
        },
        list: "/organization",
        show: "/organization/show/:id",
        edit: "/organization/edit/:id",
        create: "/organization/create",
        meta: {
            hide: true,
            canDelete: true,
        },
        icon: <IconBuildingCommunity />,
    },
    {
        name: "image",
        options: {
            label: "Assets",
        },
        list: "/image",
        create: "/image/create",
        edit: "/image/edit/:id",
        show: "/image/show/:id",
        meta: {
            canDelete: false,
        },
        icon: <IconArchive />,
    },
];
/* eslint no-use-before-define: 2 */
