/* eslint-disable no-use-before-define */
// Custom types
export type EntString = string
export type EntNumber = number
export type EntBoolean = boolean
export type EntDate = Date
export type EntUUID = string
export type EntImage = string
export type EntCode = string
export type EntURL = string
export type EntRichText = string
export type EntStringList = EntString[]
export type EntNumberList = EntNumber[]
export type EntID = EntUUID | EntString


export enum EntEnums_Role {
    "user" = "user",
    "employee" = "employee",
    "teamlead" = "teamlead",
    "admin" = "admin",
    "superadmin" = "superadmin",
}

export enum EntEnums_RequestType {
    "consultation" = "SERVICE_CONSULTATION",
    "serviceInfo" = "SERVICE_INFO",
    "serviceBooking" = "SERVICE_BOOKING",
    "inquiry" = "GENERAL_INQUIRY",
}

export enum EntEnums_ServiceAddon {
    "flight" = "SERVICE_ADDON_FLIGHT",
    "hotel" = "SERVICE_ADDON_HOTEL",
    "transfer" = "SERVICE_ADDON_TRANSFER",
    "carRental" = "SERVICE_ADDON_CAR_RENTAL",
    "travel" = "SERVICE_ADDON_TRAVEL",
}

interface gqlField<T> {
    edges?: {
        nodes?: Array<T>
    },
    totalCount?: number,
}

interface localizedString {
    en?: string,
    tr?: string,
}

export interface IUser {
    id: EntString, // pulid.ID
    dob: EntDate, // time.Time
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    userID: EntUUID, // uuid.UUID
    piiToken: EntString, // string
    name: EntString, // string
    firstName: EntString, // string
    lastName: EntString, // string
    verifiedEmail: EntString, // string
    isEmailVerified: EntBoolean, // bool
    emailVerifiedAt: EntDate, // time.Time
    profilePhoto: EntString, // string
    signedProfilePhoto: EntString, // string
    lang: EntString, // string
    role: EntEnums_Role, // enums.Role
}
export interface IService {
    id: EntString, // pulid.ID
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    title: localizedString, // Map
    slug: EntString, // string
    description: EntString, // string
    content: any, // Map
    isVisible: EntBoolean, // bool
    url: EntString, // string
    parent: IService, // *Service
    parentID: EntString, // pulid.ID
    children?: IService[], // []*Service
    _children?: gqlField<IService>, // *gqlField
    images?: IImage[], // []*Image
}
export interface IImage {
    id: EntString, // pulid.ID
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    url: EntString, // string
    sized: EntString, // string
    title: EntString, // string
    isFeatured: EntBoolean, // bool
    owner: IUser,
    service?: IService[],
    _service?: gqlField<IService>,
}
export interface IClinicService {
    id: EntString, // pulid.ID
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    details: EntString, // string
    priceCurrency: EntString, // string
    priceMinValue: EntNumber, // float
    priceMaxValue: EntNumber, // float
    showPricing: EntBoolean, // bool
    service: IService, // *Service
    clinic: IClinic, // *Clinic
    images?: IImage[], // []*Image
}
export interface IClinic {
    id: EntString, // pulid.ID
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    title: any, // Map
    slug: EntString, // string
    description: EntString, // string
    content: any, // Map
    isVisible: EntBoolean, // bool
    images?: IImage[], // []*Image
    staff?: IPerson[],
    services?: IClinicService[],
    features?: IFeature[],
    _features?: gqlField<IFeature>,
}
export interface IOrganization {
    id: EntString, // pulid.ID
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    name: EntString, // String
    slug: EntString, // string
    legalName: EntString, // String
    isActive: EntBoolean, // bool
    users?: IUser[],
}
export interface IPerson {
    id: EntString, // pulid.ID
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    name: EntString, // string
    slug: EntString, // string
    description: EntString, // string
    title: EntString, // string
    content: any, // Map
    isVisible: EntBoolean, // bool
    clinic?: IClinic[],
    _clinic?: gqlField<IClinic>,
    images?: IImage[], // []*Image
}
export interface IFeatureGroup {
    id: EntString, // pulid.ID
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    title: EntString, // string
    details: EntString, // string
}
export interface IFeature {
    id: EntString, // pulid.ID
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    title: EntString, // string
    details: EntString, // string
    categories: IFeatureGroup[],
}
export interface IRequest {
    id: EntString, // pulid.ID
    createTime: EntDate, // time.Time
    updateTime: EntDate, // time.Time
    type: EntEnums_RequestType, // enums.RequestType
    addons: EntEnums_ServiceAddon[], // []enums.ServiceAddon
    owner: IUser, // *User
    notes: EntString, // string
    closed: EntBoolean, // bool
    plannedFor: EntDate, // time.Time
    referralCode: EntString, // string
    services?: IService[], // []*Service
    clinics?: IClinic[], // []*Clinic
    images?: IImage[], // []*Image
}
