import { Badge, Button, Collapse, CopyButton, Paper, Text, Title } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { useParsed, useShow } from "@refinedev/core";
import {
    Show
} from "@refinedev/mantine";
import { IconEye, IconEyeOff } from '@tabler/icons';
import Blocks from 'editorjs-blocks-react-renderer';
// import * as ENV from "environment";

import { DefaultTitleStyle } from "pages/defaults";
import { IPerson } from "pages/interfaces";

export const PageShow = () => {
    const { id } = useParsed();
    const [opened, { toggle }] = useDisclosure(false);

    const { queryResult } = useShow<IPerson>({
        id,
        resource: "person",
        meta: {
            fields: [
                "id",
                "title",
                "description",
                "name",
                "slug",
                "isVisible",
                "content",
                "createTime",
            ],
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return <Show isLoading={isLoading}>
        <Title {...DefaultTitleStyle}>Id</Title>

        <CopyButton value={record?.id as string}>
            {({ copied, copy }) => (
                <Button size="xs" compact radius="xl" color={copied ? 'lime' : ''} onClick={copy}>
                    {record?.id}
                </Button>
            )}
        </CopyButton>

        <Title {...DefaultTitleStyle} mt="md">Title</Title>
        <Text>{record?.title}</Text>

        <Title {...DefaultTitleStyle} mt="md">Name</Title>
        <Text>{record?.name}</Text>

        <Title {...DefaultTitleStyle} mt="md" >Slug</Title>
        <Text>{record?.slug}</Text>

        <Title {...DefaultTitleStyle} mt="md" >Description</Title>
        <Text>{record?.description}</Text>
        {/*<Text>{ENV.BASE_URL + '/people/' + record?.slug}</Text>*/}

        <Title {...DefaultTitleStyle} mt="md">Status</Title>
        <Text>{record?.isVisible ? <Badge color="cyan">Visible</Badge> : <Badge color="pink">Hidden</Badge>}</Text>

        <Button
            mt="lg"
            size="sm"
            variant="light"
            compact
            rightIcon={opened ? <IconEyeOff size={14} /> : <IconEye size={14} />}
            disabled={!isLoading && (Object.keys(record?.content).length <= 0)}
            loading={isLoading}
            onClick={toggle}
        >
            About / Bio
        </Button>
        <Collapse in={opened} mt="md">
            <Paper shadow="xs" p="md" withBorder>
                {!isLoading && (Object.keys(record?.content).length > 0) && <Blocks data={record?.content} />}
            </Paper>
        </Collapse>
    </Show>;

};
