import { Create, useForm, useSelect } from "@refinedev/mantine";
import { Select, TextInput, Text, Textarea, Paper, Switch, MultiSelect, } from "@mantine/core";

import { IFeature, IFeatureGroup } from "pages/interfaces";
import React from "react";

export const PageCreate: React.FC = () => {
    const { saveButtonProps, getInputProps, errors } = useForm({
        initialValues: {
            title: "",
            details: "",
            categoryIDs: [],
        },
        transformValues: (values) => ({
            title: values.title,
            details: values.details,
            categoryIDs: values.categoryIDs,
        }),
        validate: {
            title: (value) => (value.length < 2 ? "Title too short" : null),
        },
    });

    const { queryResult } = useSelect<IFeatureGroup>({
        resource: "featureGroup",
        // onSearch: (value) => [
        //     {
        //         field: "title.en",
        //         operator: "contains",
        //         value,
        //     },
        // ],
        meta: {
            fields: [
                "id",
                "title",
            ],
        },
    });

    const groupOptions = queryResult.data?.data.map((item) => ({
        label: item.title ?? item.id,
        value: item.id,
    }));

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                    label="Title"
                    placeholder="Title"
                    withAsterisk
                    {...getInputProps("title")}
                />
                <MultiSelect
                    mt={8}
                    label="Groups"
                    placeholder="Pick groups"
                    withinPortal
                    searchable
                    clearable
                    maxDropdownHeight={200}
                    {...getInputProps("categoryIDs")}
                    data={groupOptions ?? []}
                />
                <Textarea
                    mt={8}
                    label="Details"
                    placeholder="Details"
                    {...getInputProps("details")}
                />
                {errors.content && (
                    <Text mt={2} weight={500} size="xs" color="red">
                        {errors.content}
                    </Text>
                )}
            </form>
        </Create>
    );
};
