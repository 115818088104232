import { Avatar, Badge, Button, Collapse, CopyButton, Paper, Text, Title } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { useParsed, useShow } from "@refinedev/core";
import {
    Show
} from "@refinedev/mantine";
import { IconEye, IconEyeOff } from '@tabler/icons';
import Blocks from 'editorjs-blocks-react-renderer';

import { DefaultTitleStyle } from "pages/defaults";
import { IClinic } from "pages/interfaces";

export const PageShow = () => {
    const { id } = useParsed();
    const [opened, { toggle }] = useDisclosure(false);

    const { queryResult } = useShow<IClinic>({
        id,
        resource: "clinic",
        meta: {
            fields: [
                "id",
                "title",
                "description",
                "slug",
                "isVisible",
                "content",
                "createTime",
                {
                    "images": [
                        "id",
                        "sized(width: 200 height: 200)",
                    ]
                },
                {
                    "staff": [
                        "id",
                        "title",
                        "name",
                        {
                            "images": [
                                "sized(width: 64 height: 64)",
                            ]
                        }
                    ]
                },
                // {
                //     "category": [
                //         {
                //             edges: [
                //                 {
                //                     node: ["id"],
                //                 }
                //             ]
                //         }
                //     ]
                // },
            ],
        },
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return <Show isLoading={isLoading}>
        <Title {...DefaultTitleStyle} >Id</Title>

        <CopyButton value={record?.id as string}>
            {({ copied, copy }) => (
                <Button size="xs" compact radius="xl" color={copied ? 'lime' : ''} onClick={copy}>
                    {record?.id}
                </Button>
            )}
        </CopyButton>

        <Title {...DefaultTitleStyle} mt="md" >Name</Title>
        <Text>{record?.title.en}</Text>

        <Title {...DefaultTitleStyle} mt="md" >Description</Title>
        <Text>{record?.description}</Text>

        <Title {...DefaultTitleStyle} mt="md" >Slug</Title>
        <Text>{record?.slug}</Text>

        <Title {...DefaultTitleStyle} mt="md" >Status</Title>
        <Text>{record?.isVisible ? <Badge color="cyan">Visible</Badge> : <Badge color="pink">Hidden</Badge>}</Text>

        <Title {...DefaultTitleStyle} mt="md" >Staff</Title>
        {(record?.staff && record?.staff?.length > 0) ?
            <Avatar.Group spacing="sm">
                {record?.staff?.map((item) => (
                    <Avatar
                        radius="xl"
                        src={item.images?.at(0)?.sized}
                        alt={`${item.title} ${item.name}`}
                    >{item.name.charAt(0)}</Avatar>
                ))}
            </Avatar.Group>
            :
            <Text><Badge color="gray">N/A</Badge></Text>
        }

        <Button
            mt="lg"
            size="sm"
            variant="light"
            compact
            rightIcon={opened ? <IconEyeOff size={14} /> : <IconEye size={14} />}
            disabled={!isLoading && (Object.keys(record?.content).length <= 0)}
            loading={isLoading}
            onClick={toggle}
        >
            About
        </Button>
        <Collapse in={opened} mt="md">
            <Paper shadow="xs" p="md" withBorder>
                {!isLoading && (Object.keys(record?.content).length > 0) && <Blocks data={record?.content} />}
            </Paper>
        </Collapse>
    </Show>;

};
