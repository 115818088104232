import { Card, createStyles } from '@mantine/core';
import { ReactElement } from 'react';

const useStyles = createStyles((theme, _params, getRef) => {
  const image = getRef('image');

  return {
    card: {
      position: 'relative',
      height: 200,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],

      [`&:hover .${image}`]: {
        transform: 'scale(1.03)',
      },
    },

    image: {
      ref: image,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundSize: 'cover',
      transition: 'transform 500ms ease',
    },

    overlay: {
      position: 'absolute',
      top: '20%',
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .65) 90%)',
    },

    content: {
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      zIndex: 1,
    },
  };
});

interface CardImageGridProps {
  image: string;
  bottomBar: ReactElement
}

export const CardImageGrid: React.FC<CardImageGridProps> = ({ image, bottomBar }) => {
  const { classes } = useStyles();

  return (
    <Card
      p="lg"
      shadow="lg"
      className={classes.card}
      radius="md"
    >
      <div className={classes.image} style={{ backgroundImage: `url('${image}')` }} />
      <div className={classes.overlay} />
      <div className={classes.content}>
        <div>
          {bottomBar}
        </div>
      </div>
    </Card>
  );
}
