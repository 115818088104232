import { Badge } from "@mantine/core";
import { EntEnums_ServiceAddon } from "pages/interfaces";
import React from "react";

export const BadgeAddon: React.FC<{ addonType: string, marginRight?: number }> = ({
    addonType,
    marginRight = 0
}) => {

    let badge;
    switch (addonType) {
        case EntEnums_ServiceAddon.flight:
            badge = <Badge variant="dot" style={{ marginRight: marginRight }}>Flight</Badge>;
            break;
        case EntEnums_ServiceAddon.hotel:
            badge = <Badge variant="dot" style={{ marginRight: marginRight }}>Hotel</Badge>;
            break;
        case EntEnums_ServiceAddon.transfer:
            badge = <Badge variant="dot" style={{ marginRight: marginRight }}>Transfer</Badge>;
            break;
        case EntEnums_ServiceAddon.carRental:
            badge = <Badge variant="dot" style={{ marginRight: marginRight }}>Car Rental</Badge>;
            break;
        case EntEnums_ServiceAddon.travel:
            badge = <Badge variant="dot" style={{ marginRight: marginRight }}>Travel</Badge>;
            break;
        default:
            badge = <Badge color="gray" variant="dot" style={{ marginRight: marginRight }}>Other</Badge>;
            break;
    }

    return (
        badge
    );

};
