import { Create, useForm, useSelect } from "@refinedev/mantine";
import { Select, TextInput, Text, Paper, Switch } from "@mantine/core";

import { IOrganization } from "pages/interfaces";
import React from "react";

export const PageCreate: React.FC = () => {
    const { saveButtonProps, getInputProps, errors } = useForm({
        initialValues: {
            name: "",
            legalName: "",
            isActive: "",
        },
        transformValues: (values) => ({
            name: values.name,
            legalName: values.legalName,
            isActive: values.isActive,
        }),
        validate: {
            name: (value) => (value.length < 2 ? "Name too short" : null),
            legalName: (value) => (value.length < 2 ? "Legal Name too short" : null),
            // parent: {
            //     id: (value) =>
            //         value.length <= 0 ? "Category is required" : null,
            // },
            // content: (value) =>
            //     value.length < 10 ? "Too short content" : null,
        },
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                    label="Name"
                    placeholder="Name"
                    withAsterisk
                    {...getInputProps("name")}
                />
                <TextInput
                    mt={8}
                    label="Legal Name"
                    placeholder="Legal Name"
                    withAsterisk
                    {...getInputProps("legalName")}
                />
                <Text mt={8} weight={500} size="sm">
                    Active
                </Text>
                <Switch
                    size="md"
                    labelPosition="left"
                    {...getInputProps('isActive', { type: 'checkbox' })}
                />
                {errors.content && (
                    <Text mt={2} weight={500} size="xs" color="red">
                        {errors.content}
                    </Text>
                )}
            </form>
        </Create>
    );
};
