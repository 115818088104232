import React from "react";
import { useTranslate, MetaQuery, useCustomMutation } from "@refinedev/core";
import { mapButtonVariantToActionIconVariant } from "@refinedev/mantine";
import { ActionIcon, Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import type { SaveButtonProps } from "@refinedev/mantine";

export interface AddButtonProps extends SaveButtonProps {
  title?: string,
  meta?: MetaQuery,
  onSuccess?: (data: any) => void,
}

/**
 * `<AddButton>` uses Mantine {@link https://mantine.dev/core/button/ `<Button> `}.
 * It uses it for presantation purposes only. Some of the hooks that refine has adds features to this button.
 *
 * @see {@link https://refine.dev/docs/api-reference/mantine/components/buttons/save-button} for more details.
 */
export const AddButton: React.FC<AddButtonProps> = ({
  hideText = false,
  svgIconProps,
  children,
  onSuccess,
  ...rest
}) => {
  const translate = useTranslate();

  const { variant, styles, onClick, ...commonProps } = rest;

  const { mutate } = useCustomMutation();

  const handler = () => mutate({
    url: "",
    method: "post",
    meta: rest.meta,
    values: {},
  },
    {
      onSuccess: onSuccess
    }
  )

  return hideText ? (
    <ActionIcon
      {...(variant
        ? {
          variant: mapButtonVariantToActionIconVariant(variant),
        }
        : { variant: "filled", color: "primary" })}
      className="refine-save-button"
      {...commonProps}
    >
      <IconPlus size={18} {...svgIconProps} />
    </ActionIcon>
  ) : (
    <Button
      variant="filled"
      leftIcon={<IconPlus size={18} {...svgIconProps} />}
      className="refine-save-button"
      onClick={onClick ?? handler}
      {...rest}
    >
      {children ?? translate("buttons.add", "Add")}
    </Button>
  );
};
